import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  CircularProgress,
  Input,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Fixed import
import { iparhaiServer } from "../../store/services/config/api.config";

const AssigmentAccordion = ({ no, id, question, pdfUrl, totalMarks, grade, fileId }) => {
  const [loading, setLoading] = useState(false);
  const [newGrade, setNewGrade] = useState(grade);
  const [assignmentFile, setAssignmentFile] = useState(null);

  const handleAutograde = async () => {
    if (!assignmentFile) {
      alert("Please upload a sample answer file first!");
      return;
    }

    setLoading(true); // Start loader

    try {
      const formData = new FormData();
      formData.append("sample_answer", assignmentFile);
      formData.append("total_score", totalMarks);

      const { data } = await iparhaiServer.post(
        `/api/question/aws/gradeAssignment/${id}`,
        formData
      );
      console.log(data)

      if (data) {
        setNewGrade(data || "N/A"); // Update grade
      }
    } catch (error) {
      console.error(error.response || error.message); // Handle errors appropriately
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAssignmentFile(file);
    }
  };

  return (
    <Accordion key={no}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <div className="w-full flex justify-between">
          <Typography>
            <span className="font-semibold">QUESTION No. {no + 1}:</span>{" "}
            {question}
          </Typography>
          <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg ">
            <span className="font-medium">Total Marks:</span>{" "}
            <span className="text-[#004e8f] font-semibold">{totalMarks}</span>
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          sx={{
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          }}
          className="max-w-[95%]"
        >
          <span className="font-semibold">Assignment:</span>
        </Typography>
        <div className="flex w-96 mx-auto items-center gap-4 my-4 flex-col">
          <label className="text-sm" htmlFor={`file-input-${id}`}>
            <Button
              size="small"
              className="text-sm"
              variant="contained"
              component="span"
              style={{
                backgroundColor: assignmentFile ? "red" : "primary",
                color: "#fff",
              }}
            >
              {assignmentFile ? "Sample Answer Uploaded" : "Upload Sample Answer"}
            </Button>
          </label>
          <Input
            accept="application/pdf"
            id={`file-input-${id}`}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <Button
            variant="contained"
            onClick={handleAutograde}
            component="span"
            color={newGrade ? "success" : "primary"}
            disabled={loading} // Disable button when loading
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" /> // Show loader
            ) : newGrade ? (
              "Auto Graded"
            ) : (
              "Auto Grade"
            )}
          </Button>
        </div>
        <div className="h-[700px] flex justify-center">
          <iframe
            src={pdfUrl ? pdfUrl : `https://api.proctorparhai.com/api/question/questionBank/getFileFormMongo/${fileId}`}
            width="70%"
            height="100%"
            title="PDF Preview"
            style={{ border: "none" }} // Removes border
          ></iframe>
        </div>
        <div className="border mt-4">
          <p className="text-xl font-semibold">
            GRADE: <span className=" px-4 py-2 text-sm rounded-lg" dangerouslySetInnerHTML={{ __html: newGrade }} />
          </p>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AssigmentAccordion;
