import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Authservice from "../store/services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import user from "../store/actions/user";
import { Alert } from "@mui/material";

const OTPScreen = () => {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState([false, ""]);
  const [otpSent, setOTPSent] = useState(false);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  async function handleLogout() {
    try {
      await localStorage.removeItem("token");
      dispatch(user.setUser({}));
      navigate("/");
    } catch (error) {}
  }
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    setEmail(storedEmail);
  }, []);
  const handleChange = (event) => {
    setOTP(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (otp.length > 6) {
      setError([true, "OTP should not be greater than 6 digits"]);
      return;
    }
    try {
      const response = await Authservice.verifyotp(email, otp);
      if (localStorage.getItem("token")) {
        alert("Otp Successfull");
        navigate("/user");
      } else {
        alert("Otp Successfull Please login Now");
      }
      setError([false, ""]);
    } catch (error) {
      setError([true, "Error Wrong Otp or User already verified"]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      {error[0] && (
        <Alert severity="error" style={{ marginBottom: "20px" }}>
          {error[1]}
        </Alert>
      )}
      <Typography variant="h4" gutterBottom>
        Enter OTP
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "20px", textAlign: "center" }}
      >
        An OTP has been sent to your email If you didnt recieve please check
        Spam in your mail.
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Enter your OTP"
          variant="outlined"
          type="text"
          value={otp}
          onChange={handleChange}
          inputProps={{ maxLength: 6 }}
          style={{ marginBottom: "20px", width: "200px" }}
        />
        <br />
        <Button variant="contained" color="primary" type="submit">
          Verify OTP
        </Button>
        <br />
        <br />
        <Button
          onClick={handleLogout}
          variant="contained"
          color="primary"
          type="submit"
        >
          {" "}
          <faBackward />
          Back To Login
        </Button>
      </form>
    </div>
  );
};

export default OTPScreen;
