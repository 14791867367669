

const initialState = {
    proctorcount: 0,
    trueCount:0,
    objResponses: [], 
    noisecount:0,
    leftcount:0,
    centercount:0,
    rightcount:0,
    upcount:0,
    downcount:0,
    similarityscore: 0,
    noface:0,
   
  };
  
  const proctorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PROCTOR_COUNT':
        return {
          ...state,
          proctorcount: action.count, 
        };
        case 'SET_TRUE_COUNT':
          return {
            ...state,
            trueCount: action.trueCount, 
          };

          case 'SET_OBJ_RESPONSES':
      return {
        ...state,
        objResponses: action.responses,
      };
      case 'SET_NOISE_COUNT':
        return {
          ...state,
          noisecount: action.noisecount,
        };
        case 'SET_RIGHT_COUNT':
          return {
            ...state,
            rightcount: action.rightcount,
          };
          case 'SET_LEFT_COUNT':
            return {
              ...state,
              leftcount: action.leftcount,
            };
            case 'SET_CENTER_COUNT':
              return {
                ...state,
                centercount: action.centercount,
              };
              case 'SET_UP_COUNT':
                return {
                  ...state,
                  upcount: action.upcount,
                };
                case 'SET_DOWN_COUNT':
                  return {
                    ...state,
                    downcount: action.downcount,
                  };
                  case 'SET_SIMILARITY_SCORE':
                    return {
                      ...state,
                      similarityscore: action.similarityscore,
                    };
                    case 'SET_SIMILARITY_SCORE':
                      return {
                        ...state,
                        similarityscore: action.similarityscore,
                      };
                      case 'SET_NO_FACE':
                        return {
                          ...state,
                          noface: action.noface,
                        };

    default:
      return state;
  }
};
      
      export default proctorReducer;