const stateInit = { type: false , companyId: null,};

function authReducer(state = stateInit, action) {
  switch (action.type) {
    case "login":
      return {
        ...state,
        type: action.payload,
      };
      case 'SET_COMPANY_ID':
        return {
          ...state,
          companyId: action.payload,
        };
    default:
      return state;
  }
}

export default authReducer;
