const setTests = (payload) => {
  return {
    type: "update",
    payload,
  };
};

const updateQuestions = (payload) => {
  return {
    type: "updateQuestions",
    payload,
  };
};
const updateAnswers = (payload) => {
  return {
    type: "updateAnswers",
    payload,
  };
};

const updatePercentage = (payload) => {
  return {
    type: "updatePercentage",
    payload,
  };
};
export const updateDuration = (payload) => {
  return {
    type: "updateDuration",
    payload,
  };
};
const updateTestId = (payload) => {
  return {
    type: "updateTestId",
    payload,
  };
};
export const settranscript = (payload) => {
  return {
    type: "updatetranscripttext",
    payload,
  };
};
export const updateMode = (payload) => {
  return {
    type: "updatemode",
    payload,
  };
};
export const updateaiproctor = (payload) => {
  return {
    type: "updateaiproctor",
    payload,
  };
};
export const updateenableNegativeMarking = (payload) => {
  return {
    type: "updatenegmarks",
    payload,
  };
};
export const updatemarksToBeDeducted = (payload) => {
  return {
    type: "updatedetectmark",
    payload,
  };
};
export const updateExpireDate = (payload) => {
  return {
    type: "EXPIRE_DATE",
    payload,
  };
};
export const reloadPage = (payload) => {
  return {
    type: "RELOAD_PAGE",
    payload,
  };
};

export default {
  updateTestId,
  setTests,
  reloadPage,
  updateExpireDate,
  updateQuestions,
  updateAnswers,
  updatePercentage,
  updateMode,
  updateDuration,
  updateTestId,
  updateaiproctor,
  updateenableNegativeMarking,
  updatemarksToBeDeducted,
};
