import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Checkbox,
  Button,
  FormControlLabel,
  Alert,
  Box,
} from "@mui/material";
import { IconButton, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const Creationform = ({
  assessmentName = "",
  setAssessmentName,
  assessmentDescription = "",
  setAssessmentDescription,
  questionType = "",
  gradelevel = "",
  setgradelevel,
  setQuestionType,
  assessmentDuration = "",
  setAssessmentDuration,
  startDate = "",
  setStartDate,
  endDate = "",
  setEndDate,
  enableCopyPaste,
  setEnableCopyPaste,
  enablefeedback = false,
  setEnablefeedback,
  enableShuffle,
  setEnableShuffle,
  subject,
  setSubject,
  enableAIVigilance = false,
  setEnableAIVigilance,
  handleNext,
  enableNegativeMarking = false,
  setEnableNegativeMarking,
  marksToBeDeducted,
  setMarksToBeDeducted,
  error = [false, ""],
  setError,
  fieldStyle = { marginBottom: "16px" },
  setTimezone,
  timezone,
  noOfQuestions,
  setNoOfQuestions,
  enableRubric,
  setEnableRubric,
}) => {
  const [anchorElGrade, setAnchorElGrade] = useState(null);
  const [anchorElTimezone, setAnchorElTimezone] = useState(null);
  const [assessmentType, setAssessmentType] = useState(null);

  const handlePopoverClose = () => {
    setAnchorElGrade(null);
    setAnchorElTimezone(null);
  };

  const timeZones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
  ];

  return (
    <div>
      <br />
      <TextField
        fullWidth
        label={
          <Typography variant="subtitle1" gutterBottom>
            Assessment Name
          </Typography>
        }
        placeholder="Assessment Name"
        value={assessmentName}
        onChange={(e) => setAssessmentName(e.target.value)}
        style={fieldStyle}
      />
      <TextField
        fullWidth
        label={
          <Typography variant="subtitle1" gutterBottom>
            Subject / Topic
          </Typography>
        }
        placeholder="Subject Name"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        style={fieldStyle}
      />
      <TextField
        fullWidth
        label={
          <Typography variant="subtitle1" gutterBottom>
            Assessment Description
          </Typography>
        }
        placeholder="Assessment Description"
        multiline
        rows={4}
        value={assessmentDescription}
        onChange={(e) => setAssessmentDescription(e.target.value)}
        style={fieldStyle}
      />
      <FormControl fullWidth style={fieldStyle}>
        <InputLabel id="assessment-type-label">Assessment Type</InputLabel>
        <Select
          labelId="assessment-type-label"
          id="assessment-type-select"
          value={questionType}
          onChange={(e) => {
            setAssessmentType(e.target.value);
            setQuestionType(e.target.value);
          }}
          displayEmpty
        >
          <MenuItem value="MCQ-based">MCQ based</MenuItem>
          <MenuItem value="Text-based">Text Based</MenuItem>
          <MenuItem value="Assignment-based">Assignment Based</MenuItem>
        </Select>
      </FormControl>
      {(assessmentType === "Text-based" ||
        assessmentType === "Assignment-based") && (
        <Box display="flex" alignItems="center" style={fieldStyle}>
          <TextField
            fullWidth
            select
            label="Grade level"
            value={gradelevel}
            onChange={(e) => setgradelevel(e.target.value)}
          >
            <MenuItem value="Primary">Primary</MenuItem>
            <MenuItem value="Higher">High</MenuItem>
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="Bachelors">Bachelors</MenuItem>
            <MenuItem value="Masters">Masters</MenuItem>
            <MenuItem value="Ph.D">Ph.D</MenuItem>
          </TextField>
          <IconButton
            size="small"
            aria-label="info"
            onClick={(e) => setAnchorElGrade(e.currentTarget)}
            style={{ marginLeft: "8px" }}
          >
            <InfoIcon />
          </IconButton>
        </Box>
      )}
      <Popover
        open={Boolean(anchorElGrade)}
        anchorEl={anchorElGrade}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography style={{ margin: "12px", maxWidth: "300px" }}>
          Information about Grade Levels. For taking admission tests, please
          select one level below.
        </Typography>
      </Popover>
      {questionType === "MCQ-based" && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "16px",
              marginTop: "16px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableNegativeMarking}
                  onChange={(e) => setEnableNegativeMarking(e.target.checked)}
                />
              }
              label="Negative Marking per Question"
            />
          </div>
          {enableNegativeMarking && (
            <TextField
              fullWidth
              label={
                <Typography variant="marks" gutterBottom>
                  Marks to be Deducted
                </Typography>
              }
              placeholder="Enter marks to be deducted"
              type="number"
              inputProps={{ min: "0", step: "0.01" }}
              value={marksToBeDeducted}
              onChange={(e) => {
                const enteredValue = e.target.value;
                if (/^\d*\.?\d*$/.test(enteredValue) || enteredValue === "") {
                  setMarksToBeDeducted(enteredValue);
                }
              }}
              style={fieldStyle}
            />
          )}
        </>
      )}
      <TextField
        fullWidth
        label={
          <Typography variant="subtitle1" gutterBottom>
            Assessment Duration
          </Typography>
        }
        placeholder="Enter Duration in Minutes"
        type="number"
        inputProps={{ min: "1", max: "999" }}
        value={assessmentDuration}
        onChange={(e) => {
          const enteredValue = e.target.value;
          if (/^\d+$/.test(enteredValue) || enteredValue === "") {
            setAssessmentDuration(enteredValue);
          }
        }}
        style={fieldStyle}
      />

      <Box display="flex" alignItems="center" style={fieldStyle}>
        <TextField
          fullWidth
          select
          label="Time Zone"
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
        >
          {timeZones.map((zone, index) => (
            <MenuItem key={index} value={zone}>
              {zone}
            </MenuItem>
          ))}
        </TextField>
        <IconButton
          size="small"
          aria-label="info"
          onClick={(e) => setAnchorElTimezone(e.currentTarget)}
          style={{ marginLeft: "8px" }}
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(anchorElTimezone)}
        anchorEl={anchorElTimezone}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography style={{ margin: "12px", maxWidth: "300px" }}>
          Select your test time zone from the list. This ensures that all
          scheduled activities and deadlines are displayed correctly according
          to this time zone.
        </Typography>
      </Popover>

      <TextField
        fullWidth
        label="Start Date and Time"
        type="datetime-local"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        style={fieldStyle}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="End Date and Time"
        type="datetime-local"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        style={fieldStyle}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "16px",
          marginTop: "16px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={enableAIVigilance}
              onChange={(e) => setEnableAIVigilance(e.target.checked)}
            />
          }
          label="Enable AI Vigilance"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={enableCopyPaste}
              onChange={(e) => setEnableCopyPaste(e.target.checked)}
            />
          }
          label="Enable Copy Paste"
        />
        {assessmentType === "Text-based" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={enableRubric}
                onChange={(e) => setEnableRubric(e.target.checked)}
              />
            }
            label="Enable Rubrics"
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={enableShuffle}
              onChange={(e) => setEnableShuffle(e.target.checked)}
            />
          }
          label="Enable Shuffle"
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={enablefeedback}
            onChange={(e) => setEnablefeedback(e.target.checked)}
          />
        }
        label="Show test results/feedback to the user?"
      />
      <div>
        <Button
          variant="contained"
          onClick={handleNext}
          style={{ marginTop: "16px" }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Creationform;
