import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';
import { useState } from 'react';
import { iparhaiServer } from '../../store/services/config/api.config';
import { Backdrop, Button, Fade, Modal, TextField } from '@mui/material';
import Sidebar from '../Navigation/SideBar';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import Pagination from '@mui/material/Pagination';
import { formatDate } from '../../utils/scheduleCard.function';

function Row(props) {
    const { row, adminData, filterName, filterOrganization } = props;
    const [open, setOpen] = useState(false);
    const [update, setUpdate] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [email, setEmail] = useState('')
    const [minutes, setMinutes] = useState(null)

    useEffect(() => {

    }, [email])

    const handleToggle = async (e) => {
        try {
            await iparhaiServer.post('/api/userAuth/accountBlock', { block: e.target.checked, email: row.email })
            adminData()
        } catch (error) {
            // console.log(error.response)
        }
    }

    const handleDelete = async () => {
        try {
            // setDeleteModal(!deleteModal);

            const res = await iparhaiServer.delete('/api/userAuth/deleteAdmin', { data: { email } });
            adminData();
            setDeleteModal(!deleteModal);

        } catch (error) {
            // console.log(error.response);
            setDeleteModal(!deleteModal);
        }
    }

    const handleOpenModal = () => {
        setDeleteModal(!deleteModal)
        setEmail(row.email)
    }

    const handleUpdate = async() => {
        try {
            await iparhaiServer.patch('api/userAuth/updateAvailableMinutes',{minutes,email})
            adminData()
            setDeleteModal(false)
            setMinutes(null)
            
        } catch (error) {
            // console.log(error)
            setDeleteModal(false)
        }
    }

    const handleOpenUpdateModal = () => {
        setUpdate(true)
        setEmail(row.email)
        setDeleteModal(true)
    }


    const isRowFiltered = () => {
        if (
            filterName &&
            !row.name.toLowerCase().includes(filterName.toLowerCase())
        ) {
            return true;
        }
        if (
            filterOrganization &&
            !row.company.name.toLowerCase().includes(filterOrganization.toLowerCase())
        ) {
            return true;
        }
        return false;
    };

    if (isRowFiltered()) {
        return null;
    }

    return (
        <React.Fragment>
            <TableRow  >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align='center' component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.company.name}</TableCell>
                <TableCell align="center">{row.testDetails.length}</TableCell>
                <TableCell align="center">{(row.availableMinutes /60).toFixed(2)}</TableCell>
                <TableCell align="center"><Switch onChange={handleToggle} checked={row.block} /></TableCell>
                <TableCell align="center">
                    <Button onClick={handleOpenModal} sx={{ marginRight: '10px' }} variant="outlined" color='error' startIcon={<DeleteIcon />}> Delete </Button>
                    <Button onClick={handleOpenUpdateModal} variant="outlined" color='primary' > update minutes </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className='bg-blue-100 w-full ' style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} >
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Test Details
                            </Typography>
                            <Table size="small" aria-label="purchases" >
                                <TableHead>
                                    <TableRow >
                                        <TableCell className='text-2xl bg-blue-200' align="center">Test Name</TableCell>
                                        <TableCell className='text-2xl bg-blue-200' align="center">Description</TableCell>
                                        <TableCell className='text-2xl bg-blue-200' align="center">No. Applicants</TableCell>
                                        <TableCell className='text-2xl bg-blue-200' align="center">Total Submited</TableCell>
                                        <TableCell className='text-2xl bg-blue-200' align="center">Start Date</TableCell>
                                        <TableCell className='text-2xl bg-blue-200' align="center">End Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    {row.testDetails.map((test, id) => (
                                        <TableRow key={id}  >
                                            <TableCell align='center' component="th" scope="row">
                                                {test.name}
                                            </TableCell>
                                            <TableCell className='h-12' align='center'>{test.description}</TableCell>
                                            <TableCell className='h-12' align='center'>{test.enrolledUsers}</TableCell>
                                            <TableCell className='h-12' align='center'>{test.submited}</TableCell>
                                            <TableCell className='h-12' align="center">{formatDate(test.startTime)}</TableCell>
                                            <TableCell className='h-12' align="center">
                                                {formatDate(test.endTime)}
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                            {
                                row.testDetails.length === 0 && (
                                    <>
                                        <h3 className='text-center text-2xl font-medium py-3 w-full'>No Test Available</h3>
                                    </>
                                )
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteModal}
                // onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteModal}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 500,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        {
                            !update ? (<><Typography
                                id="transition-modal-title"
                                variant="h6"
                                component="h2"
                                align='center'
                            >
                                Do you want to Delete this Admin?
                            </Typography>
                                <div className="flex justify-center gap-10 mt-10">
                                    <Button onClick={handleDelete} variant="contained" size="small">
                                        YES
                                    </Button>
                                    <Button onClick={() => setDeleteModal(!deleteModal)} variant="outlined" size="small">
                                        NO
                                    </Button>
                                </div>
                            </>) : (
                                <>
                                    <TextField
                                    fullWidth
                                        id="outlined-number"
                                        label="Test Minutes"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={minutes}
                                        onChange={(e)=>setMinutes(e.target.value)}
                                    />
                                    <div className="flex justify-center gap-10 mt-10">
                                        <Button onClick={handleUpdate} variant="contained" size="small">
                                            update
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setDeleteModal(!deleteModal)
                                                setMinutes(null)
                                                setTimeout(() => {
                                                    setUpdate(false)
                                                }, 200);
                                            }}
                                            variant="outlined" size="small">
                                            cancel
                                        </Button>
                                    </div>

                                </>
                            )
                        }
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

export default function AdminDetails() {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [filterName, setFilterName] = useState(''); // State variable for name filter
    const [filterOrganization, setFilterOrganization] = useState(''); // State variable for organization filter

    useEffect(() => {
        adminData();
    }, [page, rowsPerPage, filterName, filterOrganization]);

    const adminData = async () => {
        try {
            const params = {
                page,
                limit: rowsPerPage,
            };


            if (filterName) {
                params.name = filterName;
            }

            if (filterOrganization) {
                params.organization = filterOrganization;
            }

            const res = await iparhaiServer.get('/api/userAuth/adminDetails', { params });
            // console.log(res.data)
            setData(res.data.testDetails);
            setTotalRows(res.data.count);
        } catch (error) {
            // console.log(error.response);
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(1);
    };


    return (
        <>
            <div className="flex gap-10">
                <div>
                    <Sidebar />
                </div>
                <div className="w-full">
                    <h1 className='text-4xl font-semibold my-6'>Admin Details</h1>

                    <div className="filter-section flex gap-10">
                        <TextField id="outlined-basic" label="Filter By Name" variant="outlined" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                        <TextField id="outlined-basic" label="Filter By Organization" variant="outlined" value={filterOrganization} onChange={(e) => setFilterOrganization(e.target.value)} />

                        {/* <button onClick={handleFilter}>Apply Filters</button> */}
                    </div>

                    <CachedIcon onClick={() => adminData()} className='cursor-pointer rounded-md transition-all hover:bg-gray-200 duration-300 active:rotate-90 my-4' />

                    <div className="w-[40rem] md:w-[85%] p-4 bg-[#d9e4ee] rounded-md " >
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align='center'>Name</TableCell>
                                        <TableCell align="center">Email</TableCell>
                                        <TableCell align="center">Organization</TableCell>
                                        <TableCell align="center">No. of tests</TableCell>
                                        <TableCell align="center">Minutes</TableCell>
                                        <TableCell align="center">Block</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, id) => (
                                        <Row key={id} row={row} adminData={adminData} filterName={filterName} filterOrganization={filterOrganization} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                            <Pagination
                                count={Math.ceil(totalRows / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                size="large"
                                hidePrevButton={page <= 1}
                                hideNextButton={page === Math.ceil(totalRows / rowsPerPage)}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
}
