import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  InputAdornment,
  Divider,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import QuestionPreview from "../QuestionPreview";
import { iparhaiServer } from "../../../store/services/config/api.config";
import ImagePreviewModal from "../ImagePreviewModal";

const AddModal = ({
  openAddModal,
  setOpenAddModal,
  questionData,
  setQuestionData,
  questionType,
  testId,
  excelData,
  setExcelData,
  handleTextQuestionDataChange,
  singleTextQuestion,
  setSingleTextQuestion,
  update,
  updateQuestionId,
  getQuestions,
  setMainCustomAlert
}) => {
  const [customAlert, setCustomAlert] = useState([false, ""]);

  const [imageUrl, setImageUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  const closeModal = () => {
    setOpenAddModal(false);
    setExcelData([])
    setQuestionData([])
    setSingleTextQuestion({
      type: questionType === "Text-based" ? "Short Answer" : "File",
      image: "",
      marks: "",
      question: "",
      testId
    })
    setCustomAlert([false, ""]);
  };


  useEffect(() => {
    if (customAlert[0]) {
      const timer = setTimeout(() => {
        setCustomAlert([false, ""]);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [customAlert]);

  const showAlert = (message) => {
    setCustomAlert([true, message]);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setImageLoading(true);
        const options = {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        setImageFile(compressedFile);
        // Convert compressed file to base64 string
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          setSingleTextQuestion({ ...singleTextQuestion, image: reader.result });
          setImageLoading(false);
        };
      } catch (error) {
        console.error("Error while compressing the image:", error);
        setImageLoading(false);
      }
    }
  };

  const handleUpdateQuestions = async () => {
    setAddLoading(true);
    try {
      if (update) {
        const res = await iparhaiServer.patch(
          `/api/test/editQuestion/${updateQuestionId}`,
          {
            question: singleTextQuestion,
            testId
          }
        );
        setMainCustomAlert([true, 'success', 'Question updated successfully'])
      } else {
        const res = await iparhaiServer.post(
          `/api/test/addQuestions/${testId}`,
          { questions: questionData }
        );
        setMainCustomAlert([true, 'success', 'Question added successfully'])
      }
      getQuestions();
      closeModal();
      setAddLoading(false);
    } catch (error) {
      setMainCustomAlert([true, 'error', 'Some error occur. Please try again'])
      console.log(error);
      setAddLoading(false);
    }
  };

  const handleDelete = (row, index) => {
    const newExcelData = excelData.filter((_, i) => i !== index);
    const newTextQuestionData = questionData.filter(
      (data, i) => i !== index - 1
    );

    setExcelData(newExcelData);
    setQuestionData(newTextQuestionData);
  };

  const handleOpen = (url) => {
    setPreviewImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Dialog open={openAddModal} onClose={closeModal} fullWidth>
      <DialogContent>
        {customAlert[0] && (
          <Alert sx={{ width: "fit-content" }} severity="error">
            {customAlert[1]}
          </Alert>
        )}
        <div className="space-y-2 my-2">
          <TextField
            value={singleTextQuestion.question}
            onChange={(e) =>
              setSingleTextQuestion({ ...singleTextQuestion, question: e.target.value })
            }
            fullWidth
            label="Enter Question"
            variant="outlined"
            disabled={loading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor="imageInput">
                    {imageLoading ? (
                      "Uploading..."
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ fontSize: '12px', padding: 1 }}
                        title={imageFile ? `${imageFile.name}` : "Upload Image"}
                        component="span"
                        color={imageFile ? "error" : "primary"}
                        disabled={loading}
                      >
                        {imageFile ? `${imageFile.name.substring(0, 20)}...` : "Upload Image"}
                      </Button>
                    )}
                  </label>
                  <input
                    disabled={loading}
                    accept=".jpeg, .png, .jpg"
                    style={{ display: "none" }}
                    id="imageInput"
                    type="file"
                    onClick={(event) => (event.target.value = null)}
                    onChange={handleImageUpload}
                  />
                </InputAdornment>
              ),
            }}
          />
          <div className="flex gap-2 justify-between">
            <TextField
              disabled={loading}
              label="Marks"
              type="number"
              variant="outlined"
              value={singleTextQuestion.marks}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setSingleTextQuestion({ ...singleTextQuestion, marks: value });
                }
              }}
            />

            <TextField
              disabled={loading}
              fullWidth
              select
              label="Type"
              value={singleTextQuestion.type}
              onChange={(e) =>
                setSingleTextQuestion({ ...singleTextQuestion, type: e.target.value })
              }
            >
              {questionType === "Text-based" && (
                <MenuItem value="Short Answer">Short Answer</MenuItem>
              )}
              {questionType === "Text-based" && (
                <MenuItem value="Essay">Essay</MenuItem>
              )}
              {questionType === "Assignment-based" && (
                <MenuItem value="File">File</MenuItem>
              )}
            </TextField>
          </div>
          {!update && (
            <div className="">
              <Button
                onClick={async () => {
                  if (
                    singleTextQuestion.question === "" ||
                    singleTextQuestion.type === "" ||
                    singleTextQuestion.marks === ""
                  ) {
                    showAlert("Fields can't be empty");
                    return;
                  }
                  setLoading(true);
                  const questionWithImage = {
                    ...singleTextQuestion,
                    image: imageUrl,
                  };
                  handleTextQuestionDataChange(questionWithImage);
                  setLoading(false);

                  setSingleTextQuestion({
                    type:
                      questionType === "Text-based" ? "Short Answer" : "File",
                    image: "",
                    marks: "",
                    question: "",
                  });
                  setImageFile(null);
                  setImageUrl("");
                }}
                variant="contained"
                fullWidth
                disabled={loading || imageLoading}
              >
                {loading ? "ADDING..." : imageLoading ? "WAIT.." : "ADD"}
              </Button>
            </div>
          )}
          {excelData && excelData.length > 1 && (
            <div className="mt-4">
              <Divider>PREVIEW</Divider>
            </div>
          )}

          {excelData && excelData.length > 1 && (
            <QuestionPreview
              excelData={excelData}
              handleOpen={handleOpen}
              handleDelete={handleDelete}
            />
          )}

          <ImagePreviewModal
            open={open}
            handleClose={handleClose}
            style={style}
            previewImage={previewImage}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="space-x-6">
          <Button
            variant="contained"
            disabled={addLoading}
            onClick={handleUpdateQuestions}
          >
            {addLoading ? (
              <CircularProgress size={24} className="text-red-600" />
            ) : update ? (
              "Update Question"
            ) : (
              "add questions"
            )}
          </Button>
          <Button onClick={closeModal}>CLOSE</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddModal;
