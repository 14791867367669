import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";

const Timer = ({ duration, onTimerEnd, onTimeUpdate }) => {
  const [timer, setTimer] = useState(duration); // duration in seconds

  useEffect(() => {
    const id = setInterval(() => {
      setTimer((prevTimer) => {
   
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(id); // Cleanup on component unmount
  }, [onTimeUpdate]);

  useEffect(() => {
    if (timer === 0) {
      if (onTimerEnd) {
        onTimerEnd(); // Notify when timer ends
      }
    }
  }, [timer, onTimerEnd]);

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: "primary.main",
        }}
      >
        Timer: {Math.floor(timer / 60)}:
        {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
      </Typography>
    </Box>
  );
};

export default Timer;
