import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, InputAdornment, MenuItem, TextField } from "@mui/material";
import { iparhaiServer } from "../../../store/services/config/api.config";
import ImagePreviewModal from "../ImagePreviewModal";
import McqQuestionPreview from "../McqQuestionPreview";
import { downloadFile } from "../../../utils/micsfun";

const AddMCQModal = ({
    openAddModal,
    setOpenAddModal,
    questionData,
    setQuestionData,
    questionType,
    setMainCustomAlert,
    testId,
    excelData,
    setExcelData,
    handleMcqQuestionDataChange,
    singleMcqQuestion,
    setSingleMcqQuestion,
    update,
    updateQuestionId,
    getQuestions,
}) => {
    const [imageLoading, setImageLoading] = useState({
        questionImage: false,
        optionImage1: false,
        optionImage2: false,
        optionImage3: false,
        optionImage4: false,
    });
    const [addLoading, setAddLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [customAlert, setCustomAlert] = useState([false, ""]);

    const handleImageUpload = async (event, key, index) => {
        const optionName = event.target.name.slice(0, -1);
        if (!singleMcqQuestion[event.target.name]) {
            singleMcqQuestion[event.target.name] = `${optionName} ${index + 1}`;
        }
        const file = event.target.files[0];
        if (file) {
            try {
                setImageLoading((prev) => ({ ...prev, [key]: true }));
                const options = { maxSizeMB: 0.5, maxWidthOrHeight: 1920, useWebWorker: true };
                const compressedFile = await imageCompression(file, options);
                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onloadend = () => {
                    setSingleMcqQuestion((prev) => ({ ...prev, [key]: reader.result }));
                    setImageLoading((prev) => ({ ...prev, [key]: false }));
                };
            } catch (error) {
                console.error("Error while compressing the image:", error);
                setImageLoading((prev) => ({ ...prev, [key]: false }));
            }
        } else {
            setSingleMcqQuestion((prev) => ({ ...prev, [key]: "" }));
        }
    };

    const handleUpdateQuestions = async () => {
        setAddLoading(true);
        try {
            if (update) {
                const res = await iparhaiServer.patch(`/api/test/editQuestion/${updateQuestionId}`, {
                    question: singleMcqQuestion,
                    testId
                });
                setMainCustomAlert([true, 'success', 'Question updated successfully'])
            } else {
                const res = await iparhaiServer.post(`/api/test/addQuestions/${testId}`, { questions: questionData });
                setMainCustomAlert([true, 'success', 'Question added successfully'])
            }
            getQuestions();
            closeModal();
        } catch (error) {
            setMainCustomAlert([true, 'error', 'Some error occur. Please try again'])
            console.log(error);
        } finally {
            setAddLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeModal = () => {
        setOpenAddModal(false);
        setExcelData([])
        setQuestionData([])
        setSingleMcqQuestion({
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            optionImage1: "",
            optionImage2: "",
            optionImage3: "",
            optionImage4: "",
            questionImage: "",
            marks: "",
            answer: "",
            question: ""
        })
        setCustomAlert([false, ""]);
    };

    const handleOpen = (url) => {
        setPreviewImage(url);
        setOpen(true);
    };

    const handleDelete = (index) => {
        const newExcelData = excelData.filter((_, i) => i !== index);
        const newTextQuestionData = questionData.filter(
            (data, i) => i !== index - 1
        );
        setExcelData(newExcelData);
        setQuestionData(newTextQuestionData);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <Dialog open={openAddModal} onClose={closeModal} fullWidth>
            <DialogContent>
                {customAlert[0] && <Alert severity="error">{customAlert[1]}</Alert>}
         { !update&&  <div className="flex space-x-6 my-2">
                    <Button variant="outlined" onClick={() => downloadFile("questions.xlsx")}>
                        Download Excel
                    </Button>
                    <label htmlFor="excelFileInput">
                        <Button
                            variant="contained"
                            component="span"
                            color={questionData.length > 0 ? "error" : "primary"}
                        >
                            {questionData.length > 0 ? "Change File" : "Upload Excel File"}
                        </Button>
                    </label>
                    <input
                        accept=".xlsx, .xls"
                        style={{ display: "none" }}
                        id="excelFileInput"
                        type="file"
                        onClick={(event) => (event.target.value = null)}
                        onChange={handleMcqQuestionDataChange}
                    />
                </div>}
                <div className="space-y-2">
                    <TextField
                        value={singleMcqQuestion.question}
                        onChange={(e) => setSingleMcqQuestion({ ...singleMcqQuestion, question: e.target.value })}
                        fullWidth
                        label="Enter Question"
                        variant="outlined"
                        disabled={imageLoading.questionImage}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <label htmlFor="questionImageInput">
                                        {imageLoading.questionImage ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <Button variant="contained" component="span" color={singleMcqQuestion.questionImage ? "error" : "primary"}>
                                                {singleMcqQuestion.questionImage ? "Change Image" : "Upload Image"}
                                            </Button>
                                        )}
                                    </label>
                                    <input
                                        disabled={imageLoading.questionImage}
                                        accept=".jpeg, .png, .jpg"
                                        style={{ display: "none" }}
                                        id="questionImageInput"
                                        type="file"
                                        onClick={(event) => (event.target.value = null)}
                                        onChange={(e) => handleImageUpload(e, "questionImage")}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {["option1", "option2", "option3", "option4"].map((option, index) => (
                        <TextField
                            key={option}
                            value={singleMcqQuestion[option]}
                            onChange={(e) => setSingleMcqQuestion({ ...singleMcqQuestion, [option]: e.target.value })}
                            fullWidth
                            label={`Option ${index + 1}`}
                            variant="outlined"
                            disabled={imageLoading[`optionImage${index + 1}`]}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <label htmlFor={`optionImageInput${index + 1}`}>
                                            {imageLoading[`optionImage${index + 1}`] ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    color={singleMcqQuestion[`optionImage${index + 1}`] ? "error" : "primary"}
                                                >
                                                    {singleMcqQuestion[`optionImage${index + 1}`] ? "Change Image" : "Upload Image"}
                                                </Button>
                                            )}
                                        </label>
                                        <input
                                            disabled={imageLoading[`optionImage${index + 1}`]}
                                            accept=".jpeg, .png, .jpg"
                                            style={{ display: "none" }}
                                            id={`optionImageInput${index + 1}`}
                                            type="file"
                                            name={option}
                                            onClick={(event) => (event.target.value = null)}
                                            onChange={(e) => handleImageUpload(e, `optionImage${index + 1}`, index)}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    ))}
                    <TextField
                        type="number"
                        value={singleMcqQuestion.marks}
                        onChange={(e) => setSingleMcqQuestion({ ...singleMcqQuestion, marks: e.target.value })}
                        fullWidth
                        label="Marks"
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        select
                        label="Answer"
                        value={singleMcqQuestion.answer}
                        onChange={(e) => setSingleMcqQuestion({ ...singleMcqQuestion, answer: e.target.value })}
                    >
                        {["option1", "option2", "option3", "option4"].map((option, index) => (
                            <MenuItem key={option} value={singleMcqQuestion[option]}>
                                Option {index + 1}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {!update && <div className="my-2">
                    <Button
                        onClick={async (e) => {
                            setLoading(true);
                            if (
                                singleMcqQuestion.question === "" ||
                                singleMcqQuestion.option1 === "" ||
                                singleMcqQuestion.option2 === "" ||
                                singleMcqQuestion.option3 === "" ||
                                singleMcqQuestion.option4 === "" ||
                                singleMcqQuestion.marks === "" ||
                                singleMcqQuestion.answer === ""
                            ) {
                                setCustomAlert([true, "field can't be empty"]);
                                setLoading(false);
                                return;
                            }

                            const questionWithImages = {
                                ...singleMcqQuestion,
                            };
                            console.log(questionWithImages)
                            handleMcqQuestionDataChange(e, questionWithImages);
                            setAddLoading(false);
                            setSingleMcqQuestion({
                                option1: "",
                                option2: "",
                                option3: "",
                                option4: "",
                                optionImage1: "",
                                optionImage2: "",
                                optionImage3: "",
                                optionImage4: "",
                                answer: "",
                                marks: "",
                                questionImage: "",
                                question: "",
                            });
                        }}
                        variant="contained"
                        fullWidth
                        disabled={false}
                    >
                        {false ? "ADDING..." : "ADD"}
                    </Button>
                </div>}
                {excelData.length > 1 && (
                    <div className="mt-4">
                        <Divider>{`PREVIEW ${excelData.filter(row => !row.every(cell => !cell || (typeof cell === 'object' && Object.keys(cell).length === 0))).length - 1}`}</Divider>
                    </div>
                )}

                {excelData.length > 1 && (
                    <div className="overflow-x-scroll h-fit max-h-[500px]">
                        <McqQuestionPreview excelData={excelData}
                            handleOpen={handleOpen}
                            handleDelete={handleDelete} />
                    </div>
                )}

                <ImagePreviewModal
                    open={open}
                    handleClose={handleClose}
                    style={style}
                    previewImage={previewImage}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={addLoading} onClick={handleUpdateQuestions}>
                    {addLoading ? <CircularProgress size={24} /> : update ? "Update Question" : "Add Questions"}
                </Button>
                <Button onClick={closeModal}>CLOSE</Button>
            </DialogActions>
        </Dialog >
    );
};

export default AddMCQModal;
