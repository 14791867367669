import { Box, Button, Modal } from "@mui/material";
import React from "react";

const ImagePreviewModal = ({ open, handleClose, style, previewImage }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="w-[800px] flex justify-center">
          <img
            src={previewImage}
            alt="Preview"
            style={{ maxHeight: "600px" }}
          />
        </div>
        <Button
          onClick={handleClose}
          variant="contained"
          style={{ marginTop: "10px" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ImagePreviewModal;
