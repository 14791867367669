const stateInit = {
  tests: [],
  questions: [],
  answers: [],
  percentage: 0,
  duration: 0,
  testid: 0,
  transcripttext: "",
  Mode: "",
  aiproctor: false,
  reload:false,
  enablenegmark: false,
  negmarks: 0,
};
function testReducer(state = stateInit, action) {
  switch (action.type) {
    case "update":
      return { ...state, tests: action.payload };
    case "RELOAD_PAGE":
      console.log(action.payload)
      return { ...state, reload: action.payload };
    case "updateQuestions":
      return { ...state, questions: action.payload };
    case "updateAnswers":
      return { ...state, answers: action.payload };
    case "updatePercentage":
      return { ...state, percentage: action.payload };
    case "updateDuration":
      return { ...state, duration: action.payload };
    case "updateTestId":
      return { ...state, testid: action.payload };
    case "updatetranscripttext":
      return { ...state, transcripttext: action.payload };
    case "updateaiproctor":
      return { ...state, aiproctor: action.payload };
    case "updatemode":
      return { ...state, Mode: action.payload };
    case "updatenegmarks":
      return { ...state, enablenegmark: action.payload };
    case "updatedetectmark":
      return { ...state, negmarks: action.payload };
    default:
      return state;
  }
}
export default testReducer;
