import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo192.png";

const drawerWidth = 240;
const linkItems = [
  { name: "Home", path: "/", id: "home" },
  { name: "Contact Us", path: "/contact-us", id: "contact-us" },
  { name: "Log in", path: "/login", id: "login" },
  { name: "Sign up", path: "/Signup", id: "signup" },
];

function Navbar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", bgcolor: "#004E8F", height: "100%" }}
    >
      <Typography
        variant="h6"
        sx={{
          my: 2,
          fontWeight: "700",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            backgroundColor: "white",
            height: "55px",
            marginRight: 10,
            borderRadius: "10px",
          }}
        />
      </Typography>
      <Divider />
      <List
        sx={{
          padding: 2,
        }}
      >
        {linkItems.map((item) => (
          <ListItemButton
            sx={{
              borderRadius: "5px",
              textAlign: "center",
              bgcolor: location.pathname === item.path ? "#B0C8DC" : "#004E8F",
              color: location.pathname === item.path ? "#004E8F" : "#fff",
            }}
            key={item.name}
            id={item.id}
            onClick={() => navigate(item.path)}
          >
            <ListItemText primary={item.name} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",

              // pl: { lg: "4rem", xl: 85 },
            }}
          >
            {" "}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex", alignItems: "center" },
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  backgroundColor: "white",
                  height: "55px",
                  marginRight: 10,
                  borderRadius: "10px",
                }}
              />
            </Typography>
            <Box
              sx={{
                pr: { sm: 8 },
                display: { xs: "none", sm: "flex" },
              }}
            >
              {linkItems.map((item) => (
                <Button
                  sx={{
                    borderRadius: "5px",
                    textAlign: "center",
                    bgcolor:
                      location.pathname === item.path ? "#B0C8DC" : "#004E8F",
                    color: location.pathname === item.path ? "#004E8F" : "#fff",
                    "&:hover": {
                      bgcolor: location.pathname === item.path ? "#B0C8DC" : "", // Change to desired hover background color
                      color:
                        location.pathname === item.path ? "white" : "#B0C8DC", // Change to desired hover text color
                    },
                  }}
                  key={item.name}
                  id={item.id}
                  onClick={() => navigate(item.path)}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 0 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

export default Navbar;
