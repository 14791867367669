import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

function ServiceCard(props) {
  const { title, desc, icon } = props;
  return (
    <Box display='flex' sx={{color:'primary.main'}} gap={2}>
      <FontAwesomeIcon fontSize={'1.85rem'} icon={icon} style={{paddingTop:'5px', width:'10%'}}/>
      <Box>
        <Typography color='black' fontWeight={600}>{title}</Typography>
        <Typography color='black' fontSize={'0.75rem'} >{desc}</Typography>
      </Box>
    </Box>
  );
}

export default ServiceCard;
