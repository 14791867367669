import CryptoJS from 'crypto-js';

const secretKey = "12345678912345612345678912345678"; 
const algorithm = 'aes-256-cbc'; 
const utf8SecretKey = CryptoJS.enc.Utf8.parse(secretKey);

export const decrypt = (encryptedData, iv) => {
  try {
    const ivHex = CryptoJS.enc.Hex.parse(iv);
    const decrypted = CryptoJS.AES.decrypt({
        ciphertext: CryptoJS.enc.Hex.parse(encryptedData),
    }, utf8SecretKey, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log(error)
    
  }
};
export const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    return encryptedData.toString();
  };