import { Button } from '@mui/material'
import React from 'react'

const QuestionPreview = ({ excelData, handleOpen, handleDelete }) => {
    return (
        <div>
            <table
                style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    borderCollapse: "collapse",
                    width: "100%",
                    marginTop: "10px",
                    overflowX: "scroll",
                }}
            >
                <thead>
                    {excelData.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            style={{
                                backgroundColor: rowIndex === 0 ? "#004E8F" : "",
                                color: rowIndex === 0 ? "white" : "",
                            }}
                        >
                            {row.map((cell, cellIndex) =>
                                rowIndex === 0 ? (
                                    <th
                                        key={cellIndex}
                                        style={{
                                            border: "1px solid #ddd",
                                            padding: "8px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {cell}
                                    </th>
                                ) : null
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {excelData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) =>
                                rowIndex !== 0 ? (
                                    <td
                                        key={cellIndex}
                                        style={{ border: "1px solid #ddd", padding: "8px" }}
                                    >
                                        {cellIndex === 2 && cell !== "" ? (
                                            <Button onClick={() => handleOpen(cell)}>
                                                View Image
                                            </Button>
                                        ) : cellIndex === 4 ? (
                                            <Button
                                                onClick={() => handleDelete(row, rowIndex)}
                                                color="error"
                                            >
                                                DELETE
                                            </Button>
                                        ) : (
                                            cell
                                        )}
                                    </td>
                                ) : null
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default QuestionPreview
