const initState = { selectedTest: null };

function selectedTestReducer(state = initState, action) {
  switch (action.type) {
    case "update":
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
}

export default selectedTestReducer;
