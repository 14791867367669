// import { faBrain, faDollarSign, faGear, faLightbulb, faMicrochip, faPuzzlePiece, faScroll, faWallet, faWrench } from "@fortawesome/free-solid-svg-icons";

// const initialState = {
//   services : [
//     {
//       icon: faDollarSign,
//       title: "Free Assessments",
//       desc: "Uncover areas for improvement and growth through our assessments, gaining valuable insights into your skill gaps.",
//     },
//     {
//       icon: faPuzzlePiece,
//       title: "Fully Customizable",
//       desc: "Receive personalized recommendations tailored to your specific weaknesses, guiding you on the skills that require further development.",
//     },
//     {
//       icon: faWrench,
//       title: "Vigilance Tools",
//       desc: "Access comprehensive insights and analysis of your assessment results, allowing you to track your progress, understand your strengths, and identify areas where you excel.",
//     },
//     {
//       icon: faBrain,
//       title: "High Level Insights",
//       desc: "Access comprehensive insights and analysis of your assessment results, allowing you to track your progress, understand your strengths, and identify areas where you excel.",
//     },
//   ],
//   info : [
//     {
//       icon: faGear  ,
//       title: "Vigilance Tools",
//       desc: "Stay One Step Ahead with Enhanced Vigilance: Experience seamless tab switching, open camera monitoring, and advanced object and voice detection capabilities during tests.",
//     },
//     {
//       icon: faWallet,
//       title: "Free Subscriptions",
//       desc: "Embrace Your Potential and Hiring Strategy with a Free Subscription: Unlock limitless learning and hiring opportunities across the board, free of financial commitments.",
//     },
//     {
//       icon: faScroll,
//       title: "Complete Customizations",
//       desc: "Achieve full customization with Proctor Parhai: tailor assessments to your exact specifications, from topic selection to question choice and scheduling.",
//     },
//     {
//       icon: faMicrochip,
//       title: "AI-Based Recommendations",
//       desc: "Leveraging AI Technology, the Smart Content Recommendation System Identifies Weaknesses and Provides Relevant Resources for Strengthening",
//     },
//     {
//       icon: faLightbulb,
//       title: "Comprehensive Insights",
//       desc: "Gain Valuable Insights with an Advanced Reporting System: Easily access personal and high-level candidate insights, including strengths, weaknesses, and detection of malicious acts.",
//     },
//   ],
//   toolsArray: [
//     {
//       img: "face-detection-home.png",
//       title: "Face Detection",
//       desc: "Enhance security with face detection, including eyes detection to ensure focused attention, even with glasses.",
//     },
//     {
//       img: "Gadget-detection-home.png",
//       title: "Gadget Detection",
//       desc: "Safeguard integrity with gadget detection, spotting potential risks like cellphones, smartwatches, and more.",
//     },
//     {
//       img: "voice-detection-home.png",
//       title: "voice Detection",
//       desc: "Ensure fairness with voice detection, catching any attempts at talking or external assistance.",
//     },
//     {
//       img: "tab-switching-home.png",
//       title: "Tab Switching",
//       desc: "Maintain focus and integrity with Proctor Parhai's tab switching restriction feature for assessments.",
//     },
//   ],
// };

// function toolArrayReducer(state = initialState) {
//   return state;
// }

// export default toolArrayReducer;


import {
  faBrain,
  faDollarSign,
  faGear,
  faLightbulb,
  faMicrochip,
  faPuzzlePiece,
  faScroll,
  faWallet,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

const initialState = {
  services: [
    {
      icon: faDollarSign,
      title: "Free Assessments",
      desc: "Uncover areas for improvement and growth through our assessments, gaining valuable insights into your skill gaps.",
    },
    {
      icon: faPuzzlePiece,
      title: "Fully Customizable",
      desc: "Receive personalized recommendations tailored to your specific weaknesses, guiding you on the skills that require further development.",
    },
    {
      icon: faWrench,
      title: "Vigilance Tools",
      desc: "Access comprehensive insights and analysis of your assessment results, allowing you to track your progress, understand your strengths, and identify areas where you excel.",
    },
    {
      icon: faBrain,
      title: "High Level Insights",
      desc: "Access comprehensive insights and analysis of your assessment results, allowing you to track your progress, understand your strengths, and identify areas where you excel.",
    },
  ],
  info: [
    {
      icon: faGear,
      title: "Vigilance Tools",
      desc: "Stay One Step Ahead with Enhanced Vigilance: Experience seamless tab switching, open camera monitoring, and advanced object and voice detection capabilities during tests.",
    },
    {
      icon: faWallet,
      title: "Free Subscriptions",
      desc: "Embrace Your Potential and Hiring Strategy with a Free Subscription: Unlock limitless learning and hiring opportunities across the board, free of financial commitments.",
    },
    {
      icon: faScroll,
      title: "Complete Customizations",
      desc: "Achieve full customization with Proctor Parhai: tailor assessments to your exact specifications, from topic selection to question choice and scheduling.",
    },
    {
      icon: faMicrochip,
      title: "AI-Based Recommendations",
      desc: "Leveraging AI Technology, the Smart Content Recommendation System Identifies Weaknesses and Provides Relevant Resources for Strengthening",
    },
    {
      icon: faLightbulb,
      title: "Comprehensive Insights",
      desc: "Gain Valuable Insights with an Advanced Reporting System: Easily access personal and high-level candidate insights, including strengths, weaknesses, and detection of malicious acts.",
    },
  ],
  toolsArray: [
    {
      img: "face-detection-home.png",
      title: "Face Detection",
      desc: "Enhance security with face detection, including eyes detection to ensure focused attention, even with glasses.",
    },
    {
      img: "Gadget-detection-home.png",
      title: "Gadget Detection",
      desc: "Safeguard integrity with gadget detection, spotting potential risks like cellphones, smartwatches, and more.",
    },
    {
      img: "voice-detection-home.png",
      title: "Voice Detection",
      desc: "Aim to prevent any unfair advantage that could arise from unauthorized communication or assistance.",
    },
    {
      img: "tab-switching-home.png",
      title: "Activity Detection",
      desc: "Ensure integrity of the assessment by minimizing the possibility of cheating or accessing unauthorized materials.",
    },
  ],
  features: [
    {
      img: "service1.jpg",
      title: "Recruitment",
      desc: "Elevate your recruitment process to new heights with our cutting-edge AI-based vigilance system.",
    },
    {
      img: "service2.jpg",
      title: "Professional Training",
      desc: "Empower your workforce with unparalleled professional training experiences.",
    },
    {
      img: "service3.jpg",
      title: "Assessments",
      desc: "Secure the integrity of academic assessments with ProctorParhai's state-of-the-art vigilance system.",
    },
    {
      img: "service4.jpg",
      title: "TopicDriven learning",
      desc: "Revolutionize education with our AI-based vigilance system, ensuring integrity of assessments.",
    },
  ],
  intro: [
    {
      img: "quiz.jpg",
      title: "Quiz/Assessments",
      desc: " Ensure academic integrity and discourage dishonest practices, creating a trustworthy assessment environment.",
    },
    {
      img: "admission.jpg",
      title: "Admission Tests",
      desc: "Secure the integrity of academic assessments with Proctor Parhai's state-of-the-art vigilance system, designed for admissions.",
    },
    {
      img: "recruitment.jpg",
      title: "Recruitment",
      desc: "Ensure a fair and transparent recruitment experience, providing unparalleled precision in talent acquisition.",
    },
    {
      img: "training.jpg",
      title: "Professional Training",
      desc: "Empower your educational institution or organization with ProctorParhai's unmatched professional training experiences.",
    },
  ],
};

function toolArrayReducer(state = initialState) {
  return state;
}

export default toolArrayReducer;
