import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import CopyrightIcon from "@mui/icons-material/Copyright";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  LocalPhoneOutlined,
  Twitter,
} from "@mui/icons-material";

// import auth from "../../store/actions/auth";

export default function Footer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
        },
        bottom: 0,
        width: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "#004E8F",
          padding: "20px",
          mt: "20px",
          textAlign: "center",
          fontSize: "14px",
          color: "white",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginBottom: "1vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8vw",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "large",
              }}
            >
              ProctorParhai
            </Box>
            <Box>
              <AlternateEmailIcon />
              info@proctorparhai.com
            </Box>
            <Box>
              <LocalPhoneOutlined />
              (+92) 3123456789
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8vw",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "large",
              }}
            >
              Company
            </Box>
            <Box>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                to="/"
              >
                Home
              </Link>
            </Box>
            <Box>Evaluate Skills</Box>
            <Box>
              {" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                to="/contact-us"
              >
                Contact Us
              </Link>
            </Box>
            <Box>
              {" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                to="/policy"
              >
                Privacy Policy
              </Link>
            </Box>
            <Box>
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                to="/terms"
              >
                Terms & Conditions
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.8vw",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "large",
              }}
            >
              Become a Member
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0.5vw",
              }}
            >
              <Box>
                <Button
                  id="login"
                  onClick={() => {
                    navigate("/login");
                  }}
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
              <Box>
                <Button
                  id="signup"
                  variant="contained"
                  onClick={() => {
                    navigate("/Signup");
                  }}
                >
                  Signup
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "1vw",
          }}
        >
          <Box>
            <CopyrightIcon />
            Copyrights {new Date().getFullYear()} proctorparhai.com
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1.1vw",
            }}
          >
            <FacebookRounded />
            <Instagram />
            <LinkedIn />
            <Twitter />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
