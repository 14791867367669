import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const students = [
  { userName: 'Hamza',testname:"Machine Learning ", score: 95, proctorscore:"75%"},
  { userName: 'Umair',testname:"Machine Learning " ,score: 88 ,proctorscore:"65%"},
  { userName: 'Mohsin',testname:"Machine Learning " ,score: 73 ,proctorscore:"95%"},
  
];

const StudentTable = () => {
  const tableContainerStyles = {
    margin: '0 auto',
    maxWidth: 600,
  };

  const tableHeaderStyles = {
    fontWeight: 'bold',
    textAlign: 'center',
  };

  return (
    <TableContainer component={Paper} style={tableContainerStyles}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6" style={tableHeaderStyles}>
                User Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" style={tableHeaderStyles}>
                Test Name 
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" style={tableHeaderStyles}>
               test  Score
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" style={tableHeaderStyles}>
               Proctoring Score 
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student, index) => (
            <TableRow key={index}>
              <TableCell>{student.userName}</TableCell>
              <TableCell>{student.testname}</TableCell>
              <TableCell>{student.score}</TableCell>
              <TableCell>{student.proctorscore}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentTable;
