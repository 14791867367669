import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

function QuestionNum({ num, answer }) {
  return (
    <Box
      color={answer === "" ? "grey" : "primary.main"}
      sx={{ pointer: "cursor" }}
    >
      <FontAwesomeIcon icon={faSquareCheck} />
      <Typography
        color={answer === "" ? "grey" : "black"}
        component={"span"}
        sx={{ pl: 1 }}
      >
        Question {num}
      </Typography>
    </Box>
  );
}

export default QuestionNum;