// import { Box, Typography } from "@mui/material";

// function ToolCard(props) {
//   const { title, desc, img } = props;
//   return (
//     <Box
//       width={250}
//       mx="auto"
//       borderRadius={1.5}
//       boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.14)"}
//     >
//       <Box
//         component="img"
//         width={250}
//         src={require(`../../assets/${img}`)}
//         sx={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
//       />
//       <Box p={2}>

//       <Typography fontWeight={600} color={"#004E8F"}>
//         {title}
//       </Typography>
//       <Typography >{desc}</Typography>
//       </Box>
//     </Box>
//   );
// }

// export default ToolCard;

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
function ToolCard(props) {
  const { title, desc, img, handleScroll = false } = props;
  const fun = () => {
    if (handleScroll) {
      handleScroll();
    }
  };
  return (
    <>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea onClick={fun}>
          <CardMedia
            component="img"
            height="140"
            image={require(`../../assets/${img}`)}
            alt=""
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              textAlign={"left"}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign={"justify"}
            >
              {desc}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}

export default ToolCard;
