import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import React from "react";
import logo from "../../assets/logo192.png"

function TestNavbar() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: "15px",
        py: "6px",
        gap: "25px",
        bgcolor: "#004e8f",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        position: "relative",
        zIndex: "50",
      }}
    >
      <div className="bg-white rounded-lg p">
        <img src={logo} alt="logo" width={110} />
      </div>
    </Box>
  );
}

export default TestNavbar;
