import jwtDecode from "jwt-decode";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// const token = localStorage.getItem("token");

function PrivateRoute({ isAuthenticated, children, user }) {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  if(code !== null){
    localStorage.setItem('testCode', code);
    if( token && jwtDecode(token).role ===2){
      localStorage.removeItem('token')
    }
  }
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // if (user.role === 2) {
  //   return <SideBar>{children}</SideBar>;
  // }
  // return <UserSideBar>{children}</UserSideBar>;

  return <>{children}</>;
}

export default PrivateRoute;