import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { iparhaiServer } from "../../../store/services/config/api.config";

const DeleteModal = ({
  testId,
  openDeleteModal,
  handleCloseDeleteModal,
  questionId,
  getQuestions,
  setMainCustomAlert
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteQuestion = async () => {
    setLoading(true); // Start loader
    try {
      const res = await iparhaiServer.delete(
        `/api/test/deleteQuestion/${testId}&${questionId}`
      );
      getQuestions();
      setMainCustomAlert([true, 'success', 'Question deleted successfully'])
      handleCloseDeleteModal();
    } catch (error) {
      setMainCustomAlert([true, 'error', 'Some error occur. Please try again'])
      console.log(error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <Box sx={style}>
          <>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Delete Confirmation
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Do you want to delete this Question?
            </Typography>
            <div className="flex gap-4 justify-center mt-4">
              <Button
                variant="outlined"
                color="error"
                onClick={handleDeleteQuestion}
                disabled={loading} // Disable button when loading
              >
                {loading ? <CircularProgress size={24} /> : "Delete"}{" "}
                {/* Show loader */}
              </Button>
              <Button onClick={handleCloseDeleteModal} disabled={loading}>
                Cancel
              </Button>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
