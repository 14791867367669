const login = (value) => ({
  type: "login",
  payload: value,
});

export default { login };

export const setCompanyId = (companyId) => ({
  type: 'SET_COMPANY_ID',
  payload: companyId,
});