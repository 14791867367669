import { faClock, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomizeModule from "../../components/Assessment/CustomizeModule";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {} from "../../utils/micsfun";
import Sidebar from "../../components/Navigation/SideBar";

function CustomizeTest() {
  const user = useSelector((state) => state.user);

  const [openSchedule, setOpenSchedule] = useState(false);
  const [loading, setLoading] = useState(true);
  const selectedTest = useSelector((state) => state.selectedTest.type);
  const questionLength = selectedTest && selectedTest.questions.length;
  const duration = selectedTest && selectedTest.duration;

  useEffect(() => {
    if (selectedTest) {
      setLoading(false);
    }
  }, [selectedTest]);

  const closeModal = () => {
    setOpenSchedule((prev) => !prev);
  };

  return (
    <>
      <Box
        height="100%"
        sx={{
          position: "relative",
          bgcolor: "#F8F8F8",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={0} sm={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={10} sm={9}>
            {loading ? (
              <Box sx={{ py: 5 }}>
                <Typography>Loading...</Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  py: 5,
                  mx: {
                    xs: "0", //  above 1920px
                    sm: "5.5rem", //  above 1920px
                  },
                  height: "100vh",
                }}
              >
                <CustomizeModule
                  open={openSchedule}
                  close={closeModal}
                  selectedTest={selectedTest}
                />
                <Typography fontSize={17} fontWeight={800}>
                  Customize & Schedule Assessment
                </Typography>
                <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                  <Typography variant="body2">
                    <FontAwesomeIcon icon={faQuestionCircle} /> {questionLength}
                  </Typography>
                  <Typography variant="body2">
                    <FontAwesomeIcon icon={faClock} /> {duration} Hours
                  </Typography>
                </Box>
                <Typography sx={{ mt: 4 }}>
                  {selectedTest && selectedTest.description}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
                  <Link to="..">
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "primary.lighter" }}
                    >
                      Back
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOpenSchedule((prev) => !prev);
                    }}
                  >
                    Schedule & Invite
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CustomizeTest;
