import {
  faCalendar,
  faClock,
  faMinusCircle,
  faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Input,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import testService from "../../store/services/test.service";
import selectedTest from "../../store/actions/selectedTest";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

function CustomizeModule({ open, close, handleClose, selectedTest }) {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUsersArray, setSelectedUsersArray] = useState([]);
  const [mySqlDate, setSqlDate] = useState("");
  const [testCode, setTestCode] = useState();
  const [selectedTime, setSelectedTime] = useState({
    hour: null,
    minute: null,
    type: "",
  });

  const token = localStorage.getItem("token");

  const scheduleTest = () => {
    updateTime();
    if (mySqlDate !== "") {
      testService
        .scheduleTest(selectedTest.id, jwtDecode(token).company, mySqlDate)
        .then((res) => {
          alert(res.message);
        })
        .catch((err) => {
        });
    } else {
      alert("Please Add a Proper Date");
    }
  };

  const users = [
    { name: "Umair", email: "umair.abbas@nu.edu.pk" },
    { name: "Test", email: "test@test.com" },
    { name: "Hamza", email: "hamza@gmail.com" },
  ];

  function selectParticipants() {
    const isUserSelected = selectedUsersArray.some(
      (user) => user.email === selectedUser
    );

    if (!isUserSelected) {
      const selectedParticipant = users.filter(
        (user) => user.email === selectedUser
      );
      setSelectedUsersArray([...selectedUsersArray, ...selectedParticipant]);
    }
  }

  const removeParticipants = (email) => {
    const updatedSelectedUsersArray = selectedUsersArray.filter(
      (user) => user.email !== email
    );
    setSelectedUsersArray(updatedSelectedUsersArray);
  };

  useEffect(() => {
    selectedTest && setTestCode(selectedTest.code);
  }, [selectedTest]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (event) => {
    setSelectedTime({
      ...selectedTime,
      [event.target.name]: event.target.value,
    });
  };

  const updateTime = () => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(selectedDate.getDate()).padStart(2, "0");
    let hours = selectedTime.hour;
    const minutes = selectedTime.minute;
    const seconds = String(selectedDate.getSeconds()).padStart(2, "0");

    if (selectedTime.type === "PM") {
      hours += 12;
    }
    hours = String(hours).padStart(2, "0");

    const mysqlDatetime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    setSqlDate(mysqlDatetime);
  };

  return (
    <Modal open={open} sx={{ top: "20%", left: "30%" }}>
      <Paper sx={{ width: "70%", p: 5 }}>
        <Typography fontSize={18} fontWeight={800}>
          Select Preferred Deadline
        </Typography>
        <Box sx={{ display: "flex", gap: 2, mt: 2, ml: 3 }}>
          <Box>
            <Typography fontWeight={800}>Date</Typography>
            <Paper
              sx={{
                p: 1,
                mt: 1,
                display: "flex",
                gap: 1,
                alignItems: "center",
                color: "primary.main",
                height: "3em",
              }}
            >
              <FontAwesomeIcon icon={faCalendar} />{" "}
              <Box sx={{ zIndex: 5 }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                />{" "}
              </Box>
              <FontAwesomeIcon icon={faUpDown} />
            </Paper>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography fontWeight={800}>Time</Typography>
            <Paper
              sx={{
                p: 1,
                mt: 1,
                display: "flex",
                gap: 1,
                alignItems: "center",
                color: "primary.main",
              }}
            >
              <FontAwesomeIcon icon={faClock} />
              <Select
                sx={{ height: "2em" }}
                name="hour"
                value={selectedTime.hour}
                onChange={handleTimeChange}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
              :
              <Select
                sx={{ height: "2em" }}
                name="minute"
                value={selectedTime.minute}
                onChange={handleTimeChange}
              >
                {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
                  <MenuItem key={minute} value={minute}>
                    {minute < 10 ? `0${minute}` : minute}
                  </MenuItem>
                ))}
              </Select>{" "}
              -
              <Select
                sx={{ height: "2em" }}
                name="type"
                value={selectedTime.type}
                onChange={handleTimeChange}
              >
                <MenuItem value={`PM`}>PM</MenuItem>
                <MenuItem value={"AM"}>AM</MenuItem>
              </Select>
              <FontAwesomeIcon icon={faUpDown} />
            </Paper>
          </Box>
        </Box>

        <Typography fontSize={18} fontWeight={800}>
          Add participants
        </Typography>
        <Box sx={{ mt: 2, ml: 3 }}>
          <Paper sx={{ p: 1, display: "flex" }}>
            <Autocomplete
              options={users.map((user) => {
                return user.email;
              })}
              sx={{ width: "75%", mr: 5 }}
              value={selectedUser && selectedUser.email}
              onChange={(event, newValue) => {
                const user = users.filter((user) => {
                  return user.email === newValue;
                });
                setSelectedUser(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="user@service.com" />
              )}
            />
            <Button variant="contained" onClick={selectParticipants}>
              Add Participant +
            </Button>
          </Paper>
          <Box sx={{ display: "flex", gap: 2, mt: 1, overflowX: "scroll" }}>
            {selectedUsersArray.length > 0 &&
              selectedUsersArray.map((item) => {
                return (
                  <Box sx={{ position: "relative", p: 1 }}>
                    <Box
                      sx={{
                        color: "primary.main",
                        position: "absolute",
                        zIndex: 1,
                        top: "2%",
                        left: "4%",
                        cursor: "pointer",
                        fontSize: 19,
                      }}
                      onClick={() => {
                        removeParticipants(item.email);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </Box>
                    <Avatar sx={{ bgcolor: "secondary.gold", mb: 2 }}>
                      {item.name[0]}
                    </Avatar>
                  </Box>
                );
              })}
          </Box>
          <Paper sx={{ p: 1, display: "flex", mt: 8 }}>
            <Typography>Candidates can use this code to join : </Typography>
            <Typography sx={{ ml: 2 }} color={"primary.main"}>
              {testCode}
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ display: "flex", gap: 2, mt: 5 }}>
          <Button
            variant="contained"
            sx={{ bgcolor: "primary.lighter" }}
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              scheduleTest();
            }}
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}

export default CustomizeModule;
