// import { Box, Container, Typography } from "@mui/material";
// import { connect } from "react-redux";
// import Login from "../components/Account.js/Login";
// import Signup from "../components/Account.js/Signup";

// function Account(props) {
//   return (
//     <Box sx={{ minHeight: "91.5vh", bgcolor: "#E6EDF4" }}>
//       <Container
//         sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
//       >
//         <Box width={"45%"} my={4} p={10} bgcolor={"#B0C8DC"} borderRadius={2}>
//           <Typography variant="h6" fontWeight={800}>
//             {props.auth.type === "login"
//               ? "Log in to continue"
//               : "Sign up to continue"}
//           </Typography>
//           <Typography variant="body2">
//             {props.auth.type === "login"
//               ? "Hello, Welcome back!"
//               : "Welcome to Proctor Parhai"}
//           </Typography>
//           {props.auth.type === "login" ? <Login /> : <Signup />}
//         </Box>
//       </Container>
//     </Box>
//   );
// }

// const mapStateToProps = (state) => ({
//   ...state,
// });

// export default connect(mapStateToProps)(Account);

import { Box, Container, Typography } from "@mui/material";
import { connect } from "react-redux";
import Login from "../components/Account.js/Login";
import Signup from "../components/Account.js/Signup";

function Account({ type }) {
  return (
    <Box sx={{ minHeight: "91.5vh", bgcolor: "#E6EDF4" }}>
      <Container
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          width={{
            xs: "100%",
            sm: "70%",
            md: "50%",
          }}
          my={2}
          p={5}
          bgcolor={"#B0C8DC"}
          borderRadius={2}
        >
          <Typography variant="h6" fontWeight={800}>
            {type === "login" ? "Log in to continue" : "Sign up to continue"}
          </Typography>
          <Typography variant="body2">
            {type === "login"
              ? "Hello, Welcome back!"
              : "Welcome to Proctor Parhai"}
          </Typography>
          {type === "login" ? <Login /> : <Signup />}
        </Box>
      </Container>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Account);
