import React, { useEffect, useState } from 'react';
import {
  TextField,
  MenuItem,
  Button,
  Box,
  InputAdornment,
  Divider,
  Modal,
  Typography,
} from '@mui/material';
import imageCompression from 'browser-image-compression';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DynamicForm = ({ onQuestionsChange, excelData, questionType, settextQuestionData, textquestionData, setExcelData, setError, noOfQuestions,
  setNoOfQuestions }) => {
  const [singleQuestion, setSingleQuestion] = useState({
    type: questionType === "Text-based" ? 'Short Answer' : "File",
    image: "",
    marks: "",
    question: "",
  });
  const [compressedImage, setCompressedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => { }, [imageUrl, singleQuestion]);

  const handleDelete = (row, index) => {
    const newExcelData = excelData.filter((_, i) => i !== index);
    const newTextQuestionData = textquestionData.filter((data, i) => i !== index - 1);

    setExcelData(newExcelData);
    settextQuestionData(newTextQuestionData);
  };

  const handleOpen = (url) => {
    setPreviewImage(url);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setImageLoading(true);
        const options = {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        setCompressedImage(compressedFile);
        setImageFile(compressedFile);

        // Convert compressed file to base64 string
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          setImageUrl(reader.result);
          setImageLoading(false);
        };
      } catch (error) {
        console.error('Error while compressing the image:', error);
        setImageLoading(false);
      }
    }
  };

  return (
    <>
      <div className="space-y-2 my-2">
        <TextField
          value={singleQuestion.question}
          onChange={(e) =>
            setSingleQuestion({ ...singleQuestion, question: e.target.value })
          }
          fullWidth
          id="outlined-basic"
          label="Enter Question"
          variant="outlined"
          disabled={loading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="imageInput">
                  {imageLoading ? (
                    "Uploading..."
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        component="span"
                        color={singleQuestion.image ? "error" : "primary"}
                        disabled={loading}
                      >
                        {imageFile ? "Change Image" : "Upload Image"}
                      </Button>
                    </>
                  )}
                </label>
                <input
                  disabled={loading}
                  accept=".jpeg, .png, .jpg"
                  style={{ display: "none" }}
                  id="imageInput"
                  type="file"
                  onClick={(event) => (event.target.value = null)}
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <div className="flex gap-2 justify-between">
          <TextField
            disabled={loading}
            id="outlined-basic"
            label="Marks"
            type='number'
            variant="outlined"
            value={singleQuestion.marks}
            onChange={(e) => {
              const value = e.target.value;
              if (value >= 0) {
                setSingleQuestion({ ...singleQuestion, marks: value });
              }
            }}
          />

          <TextField
            disabled={loading}
            fullWidth
            select
            label="Type"
            value={singleQuestion.type}
            onChange={(e) => setSingleQuestion({ ...singleQuestion, type: e.target.value })}
          >
            {questionType === "Text-based" && (
              <MenuItem value="Short Answer">Short Answer</MenuItem>
            )}
            {questionType === "Text-based" && (
              <MenuItem value="Essay">Essay</MenuItem>
            )}

            {questionType === "Assignment-based" && (
              <MenuItem value="File">File</MenuItem>
            )}
          </TextField>


        </div>
        <div className="">
          <Button
            onClick={async () => {
              if (
                singleQuestion.question === "" ||
                singleQuestion.type === "" ||
                singleQuestion.marks === ""
              ) {
                setError([true, "field can't be empty"]);
                return;
              }
              setLoading(true);
              const questionWithImage = {
                ...singleQuestion,
                image: imageUrl,
              };
              onQuestionsChange(questionWithImage);
              setLoading(false);

              setSingleQuestion({
                type: questionType === "Text-based" ? 'Short Answer' : "File",
                image: "",
                marks: "",
                question: "",
              });
              setImageFile(null);
              setImageUrl('');
            }}
            variant="contained"
            fullWidth
            disabled={loading || imageLoading}
          >
            {loading ? "ADDING..." : imageLoading ? "WAIT.." : "ADD"}
          </Button>
        </div>
        <div className="my-6">
        <TextField
          className="w-80 my-20"
          label={
            <Typography variant="subtitle1" gutterBottom>
              Number Of Questions
            </Typography>
          }
          placeholder="Enter Number Of Questions"
          type="number"
          inputProps={{ min: "1", max: "999" }}
          value={noOfQuestions}
          onChange={(e) => {
            const enteredValue = e.target.value;
            if (/^\d+$/.test(enteredValue) || enteredValue === "") {
              setNoOfQuestions(enteredValue);
            }
          }}
        />
        <span className="block text-sm text-gray-400">Enter number of questions you want in test.</span>
      </div>
        {excelData.length > 1 && (
          <div className="mt-4">
            <Divider>PREVIEW</Divider>
          </div>
        )}

        {excelData.length > 1 && (
          <table
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "10px",
              overflowX: 'scroll',
            }}
          >
            <thead>
              {excelData.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={{
                    backgroundColor: rowIndex === 0 ? "#004E8F" : "",
                    color: rowIndex === 0 ? "white" : "",
                  }}
                >
                  {row.map((cell, cellIndex) =>
                    rowIndex === 0 ? (
                      <th
                        key={cellIndex}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          textAlign: "left",
                        }}
                      >
                        {cell}
                      </th>
                    ) : null
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              {excelData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) =>
                    rowIndex !== 0 ? (
                      <td
                        key={cellIndex}
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      >
                        {cellIndex === 2 && cell ? (
                          <>
                            <Button
                              variant="outlined"
                              onClick={() => handleOpen(cell)}
                            >
                              Preview
                            </Button>
                          </>
                        ) : cellIndex === 4 ? (
                          <Button
                            color='error'
                            variant="outlined"
                            onClick={() => handleDelete(row, rowIndex)}
                          >
                            Delete
                          </Button>
                        ) : (
                          cell
                        )}
                      </td>
                    ) : null
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="w-[800px] flex justify-center">
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxHeight: "600px" }}
            />
          </div>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default DynamicForm;
