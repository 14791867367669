import React from 'react';
import Sidebar from '../Navigation/SideBar';
import Subadminform from '../Account.js/subadminform';

function Subadmins() {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <div style={{ marginTop: 50 }}>
         <Subadminform/>
        </div>

      </div>
    </div>
  );
}

export default Subadmins;
