import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React from 'react';

const TestTable = ({ rows, columns, height }) => {
  return (
    <>
      <Box
        sx={{
          margin: '10px',
          width: {
            sm: '90%',
            md: '95%',
          },
        }}
        // display={'flex'}
        // flexDirection={'column'}
      >
        {rows.length === 0 ? (
          <Typography variant="body1" align="center" color="textSecondary">
            No data available
          </Typography>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
            sx={{ width: "100%", height: "40em" }}  
          />
        )}
      </Box>
    </>
  );
};

export default TestTable;
