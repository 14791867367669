export const setProctorCount = (count) => ({
  type: 'SET_PROCTOR_COUNT',
  count,
});

export const setTrueCountt = (trueCount) => ({
  type: 'SET_TRUE_COUNT',
  trueCount,
});

export const setobjResponses = (responses) => ({
  type: 'SET_OBJ_RESPONSES',
  responses,
});

export const setnoisecount = (noisecount) => ({
  type: 'SET_NOISE_COUNT',
  noisecount,
});
export const setLeftCount = (leftcount) => ({
  type: 'SET_LEFT_COUNT',
  leftcount,
});

export const setCenterCount = (centercount) => ({
  type: 'SET_CENTER_COUNT',
  centercount,
});

export const setRightCount = (rightcount) => ({
  type: 'SET_CENTER_COUNT',
  rightcount,
});
export const setupCount = (upcount) => ({
  type: 'SET_UP_COUNT',
  upcount,
});
export const setdownCount = (downcount) => ({
  type: 'SET_DOWN_COUNT',
  downcount,
});
export const setSimilarityscore = (similarityscore) => ({
  type: 'SET_SIMILARITY_SCORE',
  similarityscore
});

export const setnoface = (noface) => ({
  type: 'SET_NO_FACE',
  noface,
});