import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'

const QuestionsTable = ({rows,columns}) => {
    return (
        <div className='h-[95%]'>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: GridToolbar,
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[10,20]}
                disableRowSelectionOnClick
                sx={{ width: "100%", height: "100%" }}
            />

        </div>
    )
}

export default QuestionsTable
