import React, { useState } from "react";

import Footer from "../../components/Footer/Footer.js";
import "./contactUs.css";

import emailjs from "@emailjs/browser";

import Alert from "@mui/material/Alert";

function ContactUs() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const serviceId = "service_nu41t3q";
  const templateId = "template_wbqw03j";
  const publicKey = "m0wO3fMeZqyufCeL_";
  const templateParams = {
    from_name: name,
    message: message,
    from_email: email,
  };
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });
  const validate = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const Regex = /^[a-zA-Z0-9!@^()_+\[\]\|<> ]*$/;

    if (!emailRegex.test(email)) {
      setError({
        isError: true,
        errorMessage: "Please enter a valid email address.",
      });
      return false;
    }

    if (!Regex.test(name)) {
      setError({
        isError: true,
        errorMessage: "Name must not contain special characters.",
      });
      return false;
    }

    if (!Regex.test(message)) {
      setError({
        isError: true,
        errorMessage: "Message must not contain special characters.",
      });
      return false;
    }

    return true;
  };
  const send = (e) => {
    try {
      setError({
        isError: false,
        errorMessage: "",
      });
      e.preventDefault();
      if (!validate()) {
        return;
      }

      emailjs
        .send(serviceId, templateId, templateParams, publicKey)
        .then((res) => {
          setemail("");
          setname("");
          setmessage("");
          // alert(res);
        })
        .catch((err) => {
          alert(err);
        });
    } catch (error) {}
  };
  return (
    <>
      <div className="container">
        <div className="content">
          <div className="left-side">
            <div className="email details">
              <i className="ri-mail-line"></i>
              <div className="topic">Email</div>
              <div className="text-one">info@proctorparhai.com</div>
            </div>
          </div>
          <div className="right-side">
            <div className="topic-text">Send us a message</div>
            <p>
              Please don't hesitate to contact us if you'd like to request a
              demo.
            </p>
            {error.isError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error.errorMessage}
              </Alert>
            )}
            <div className="input-box">
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </div>
            <div className="input-box">
              <textarea
                placeholder="Enter your message"
                value={message}
                onChange={(e) => {
                  setmessage(e.target.value);
                }}
              ></textarea>
            </div>

            <div className="button">
              <input type="button" value="Send Now" onClick={send} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
