

import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  InputBase,
  Paper,
  Typography,
  Modal
} from "@mui/material";
import { connect } from "react-redux";
import { Container } from "@mui/system";
import Image from "mui-image";

import "react-html5video/dist/styles.css";
import img2 from "../assets/Frame 374.svg";
import img1 from "../assets/Frame 375.svg";
import img4 from "../assets/Frame 191.svg";
import img5 from "../assets/Frame 192.svg";
import img6 from "../assets/Frame 203.svg";

import user from "../store/actions/user";
import nav from "../store/actions/nav";
import ToolCard from "../components/Home/ToolCard";
import ServiceCard from "../components/Home/ServiceCard";
import InfoCard from "../components/Home/InfoCard";
import Footer from "../components/Footer/Footer";
import styled from "@emotion/styled";
import { useState } from "react";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const style = {
  width: { xs: "70%", sm: "40%", md: "30%", xl: "35%" },
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#B0C8DC",
  border: "2px solid #004E8F",
  borderRadius: "10px",
  p: 4,
};

function Home(props) {
  const navigate = useNavigate();

  const handleScroll = () => {
    const container = document.getElementById("yourContainerId");
    container.scrollIntoView({ behavior: "smooth" });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "fit-content",
  }));

  const tools = props.toolArrayReducer.toolsArray;
  const features = props.toolArrayReducer.features;
  const services = props.toolArrayReducer.services;
  const info = props.toolArrayReducer.info;
  const intro = props.toolArrayReducer.intro;

  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };
  
  const closeModal = () => setModal(false);

  const [inputs, setInputs] = useState({
    emailVerify: "",
  });

  const [verifyEmailError, setVerifyEmailError] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

  function handleOnChange(e) {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function verifyEmail() {
    setVerifyEmailError(false);
    if (emailRegex.test(inputs.emailVerify)) {
      try {
        // await authService.verifyEmail(inputs.emailVerify);
        closeModal();
      } catch (error) {
        // setVerifyEmailError(true);
      }
    } else {
      setVerifyEmailError(true);
    }
  }

  return (
    <Box>
      <Box sx={{ bgcolor: "primary.main", width: "100%" }}>
        <Container
          sx={{
            display: {
              xs: "inline-block", // 0
              sm: "inline-block", //600
              md: "inline-block", //900
              lg: "flex", //1200
              xl: "flex", //1536
            },
            p: "50px",
            // gap: "25px",
            gap: {
              xs: "5px",
              sm: "5px",
              md: "5px",
              lg: "25px",
              xl: "25px",
            },
            color: "white",
          }}
        >
          <Box sx={{ mb: "30px" }}>
            <Typography
              sx={{ fontSize: "36px", fontWeight: "700", mb: "40px" }}
            >
              {" "}
              Elevate Academic & Organizational Excellence
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                mb: "40px",
                textAlign: "justify",
              }}
            >
              Experience the power of customized assessments for your hiring
              process. Our robust platform employs advanced vigilance tools such
              as face detection, gadget detection, voice detection, and more to
              ensure highly monitored and accurate skill testing. Find your top
              talent effortlessly. Empower your hiring strategy today.
            </Typography>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={openModal}
              >
                Request Demo
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{ border: "1px solid white" }}
                onClick={handleScroll}
              >
                Exlpore More
              </Button>
            </Box>
            <Modal
  open={modal}
  onClose={closeModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  BackdropProps={{
    sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }, // Adjust the opacity as needed
  }}
>
  <Box sx={style}>
    <Button
      onClick={closeModal}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >
      Close
    </Button>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Please contact info@proctorparhai.com. Our team will get in touch with you.
    </Typography>
  </Box>
</Modal>

          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Image src={img1} width="13vw" />
            <Image src={img2} width="18vw" />
          </div>
        </Container>
      </Box>

      <Container sx={{ p: "40px" }}>
        <Typography textAlign={"center"} fontSize={"1.5rem"} fontWeight={700}>
          <Typography
            color={"primary.main"}
            fontSize={"1.5rem"}
            fontWeight={700}
            display={"inline"}
          >
            Smart
          </Typography>{" "}
          Vigilance Tools
        </Typography>
        <Typography textAlign={"justify"} my={5}>
          Gain comprehensive insights into our assessments, enhanced by our
          smart vigilance tools including gadget, face, and voice detectors.
          Experience a secure and fair assessment environment.
        </Typography>
        <Grid container spacing={1} alignSelf={"center"}>
          {tools.map((item, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Item>
                  <ToolCard
                    title={item.title}
                    desc={item.desc}
                    img={item.img}
                  />
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <Container
        sx={{
          p: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          bgcolor: "primary.lighter",
          borderRadius: "0.75%",
        }}
      >
        <Typography textAlign={"center"} fontSize={"1.5rem"} fontWeight={700}>
          <Typography
            color={"#004E8F"}
            fontSize={"1.5rem"}
            fontWeight={700}
            display={"inline"}
          >
            Services
          </Typography>
        </Typography>
        <Typography textAlign={"justify"} my={5}>
          Discover the outstanding services offered by ProctorParhai. Experience
          comprehensive assessments, seamless interviews, AI-driven candidate
          insights, and advanced vigilance tools. Optimize your hiring process
          and unlock unparalleled excellence through ProctorParhai's exceptional
          offerings.
        </Typography>
        <Grid container spacing={1}>
          {features.map((item, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Item>
                  <ToolCard
                    title={item.title}
                    desc={item.desc}
                    img={item.img}
                  />
                </Item>
              </Grid>
            );
          })}
        </Grid>
      </Container>

      <Container
        id="yourContainerId"
        sx={{
          p: "40px",
          mx: "auto",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          bgcolor={"primary.lighter"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          width="45%"
          borderRadius="8px"
          p={3}
        >
          <Typography fontSize="2rem" fontWeight={700}>
            For Enterprises
          </Typography>
          <Typography fontWeight={600} my={2}>
            Build Exceptional Tech Teams:
          </Typography>
          <Typography>
            Elevate your hiring process with TestParhai. Assess candidates'
            skills accurately and efficiently. Identify top talent to build
            exceptional tech teams that drive your company's success. Streamline
            your recruitment process and make data-driven hiring decisions with
            confidence.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "85%",
              }}
            >
              <Image src={img5} />
              <Image src={img6} />
            </Box>
            <Image src={img4} />
          </Box>
          <Box width="100%" display="flex" justifyContent="center" gap={2}>
            <Button variant="contained" color="primary" sx={{ width: "45%" }}>
              Request Demo
            </Button>
            <Button variant="contained" color="secondary" sx={{ width: "45%" }}>
              Learn More
            </Button>
          </Box>
        </Box>

        <Box width="45%" p={3}>
          <Typography fontSize={"2rem"}>
            Our{" "}
            <Typography
              display={"inline"}
              fontSize={"2rem"}
              color={"primary.main"}
              fontWeight={600}
              component={"span"}
            >
              Services
            </Typography>
          </Typography>
          <Typography my={2}>
            Explore our exceptional services at ProctorParhai. Benefit from
            comprehensive assessments, seamless interviews, AI-powered candidate
            insights, and advanced vigilance tools. Streamline your hiring
            process and unlock unparalleled excellence with ProctorParhai's
            outstanding services.
          </Typography>
          <Box display="flex" flexDirection={"column"} gap={3}>
            {services.map((item, index) => {
              return (
                <ServiceCard
                  key={index}
                  title={item.title}
                  desc={item.desc}
                  icon={item.icon}
                />
              );
            })}
          </Box>
        </Box>
      </Container>
      <Container sx={{ p: "40px", display: "flex", flexDirection: "column" }}>
        <Typography fontSize={"2rem"} textAlign={"center"}>
          Why Choose{" "}
          <Typography
            display={"inline"}
            fontSize={"2rem"}
            color={"primary.main"}
            fontWeight={600}
            component={"span"}
          >
            ProctorParhai ?
          </Typography>
        </Typography>
        <Typography textAlign={"center"} my={2.5}>
          Streamline your hiring process with seamless interviews and AI-powered
          candidate insights. Experience unrivaled excellence and unlock your
          true potential with ProctorParhai.
        </Typography>
        <Box width={"30%"} ml={"auto"}>
          <Typography
            fontWeight={600}
            display={"inline-block"}
            position={"relative"}
            left={"13%"}
          >
            ProctorParhai
          </Typography>
          <Typography
            fontWeight={600}
            display={"inline-block"}
            position={"relative"}
            left={"33%"}
          >
            Others
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          p={2}
          borderRadius={3}
          bgcolor={"#004E8F"}
        >
          {info.map((item, index) => {
            return (
              <InfoCard
                key={index}
                title={item.title}
                desc={item.desc}
                icon={item.icon}
              />
            );
          })}
        </Box>
      </Container>
      <Box sx={{ bgcolor: "white", width: "100%" }}>
        <Container
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
            p: "50px",
            gap: "25px",
            color: "black",
            alignItems: {
              xs: "center",
              lg: "start",
            },
          }}
        >
          <Box
            sx={{
              mb: "30px",
              width: {
                xs: "100%",
                lg: "200%",
              },
            }}
          >
            <Typography fontSize={"2rem"} textAlign={"center"}>
              Hiring Solutions at{" "}
              <Typography
                display={"inline"}
                fontSize={"2rem"}
                color={"primary.main"}
                fontWeight={600}
                component={"span"}
              >
                ProctorParhai
              </Typography>
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                mb: "40px",
                textAlign: "justify",
              }}
            >
              Discover our comprehensive hiring solutions at ProctorParhai.
              Revolutionize your interview process with advanced techniques,
              enabling you to make better-informed decisions. Gain complete
              insights into candidates' abilities and potential through our
              robust evaluation system. With our vigilant tools, ensure a secure
              and fair assessment environment, maintaining the integrity of the
              hiring process. Experience the power of AI-driven analytics,
              seamlessly providing valuable candidate information. Trust
              ProctorParhai to optimize your hiring strategy, streamline
              evaluations, and unlock the potential of top talent for your
              organization. Embrace efficiency, accuracy, and excellence with
              our cutting-edge hiring solutions.
            </Typography>
            <Box sx={{ display: "flex", gap: "12px" }}>
           
            </Box>
          </Box>

          <Card
            sx={{
              width: {
                xs: "50%",
                lg: "auto",
              },
            }}
          >
            <CardMedia
              component="video"
              alt="video"
              image={require(`../assets/pparhai.mp4`)}
              type="video/mp4"
              muted
              autoPlay
              controls
              loop
            />
          </Card>
        </Container>
        {/* <video controls>
      <source src='https://iparhai-videos.s3.amazonaws.com/1712658790462_1712562176536_Your+video+title.mp4' />
    </video> */}
      </Box>
      <Footer />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (name) => dispatch(user.setUser(name)),
  navSelect: (option) => dispatch(nav.select(option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
