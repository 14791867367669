import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Modal,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import QuestionsTable from "./QuestionsTable";
import AddIcon from "@mui/icons-material/Add";
import AddTextQuestionModal from "./modals/AddTextQuestionModal";
import ImagePreviewModal from "./ImagePreviewModal";
import DeleteModal from "./modals/DeleteModal";
import * as XLSX from "xlsx";
import { iparhaiServer } from "../../store/services/config/api.config";
import AddMCQModal from "./modals/AddMCQModal";
import { readFromExcel } from "../../utils/readfromexcel";

const EditQuestionsButton = ({
  openEditQuestionsModal,
  test,
  setOpenEditQuestionsModal,
}) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [customAlert, setCustomAlert] = useState([])
  const [questionLoading, setQuestionLoading] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [updateQuestionId, setUpdateQuestionId] = useState(null);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [singleMcqQuestion, setSingleMcqQuestion] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    optionImage1: "",
    optionImage2: "",
    optionImage3: "",
    optionImage4: "",
    questionImage: "",
    marks: "",
    answer: "",
    question: ""
  });
  const [singleTextQuestion, setSingleTextQuestion] = useState({
    type: test.assessmentType === "Text-based" ? "Short Answer" : "File",
    image: "",
    marks: "",
    question: "",
    testId: test.id,
  });
  const [update, setUpdate] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const memoizedTestId = useMemo(() => test.id, [test.id]);

  const getQuestions = useCallback(async () => {
    try {
      setQuestionLoading(true);
      const res = await iparhaiServer.get(`/api/test/getQuestions/${memoizedTestId}`);
      setQuestions(res.data);
      setQuestionLoading(false);
    } catch (error) {
      console.log(error);
      setQuestionLoading(false);
    }
  }, [memoizedTestId]);

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const handleOpenAddModal = () => {
    setUpdate(false);
    setSingleTextQuestion({
      type: test.assessmentType === "Text-based" ? "Short Answer" : "File",
      image: "",
      marks: "",
      question: "",
      testId: test.id,
    });
    setOpenAddModal(true);
  };

  const handleTextQuestionDataChange = (singleQuestions) => {
    setQuestionData((prev) => [...prev, singleQuestions]);

    const formattedSingleQuestions = [
      singleQuestions.question,
      singleQuestions.marks,
      singleQuestions.image,
      singleQuestions.type,
      "",
    ];

    setExcelData((prev) => {
      if (prev.length === 0) {
        return [
          ["question", "marks", "image", "type", "action"],
          formattedSingleQuestions,
        ];
      }
      return [...prev, formattedSingleQuestions];
    });
  };

  const handleMcqQuestionDataChange = async (e, singleMcqQuestion) => {
    const file = e.target.files && e.target.files[0] ? e.target.files[0] : null;
    const fileType = file && file.type

    if (file) {
      if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {

        // Handle Excel file
        setQuestionData([]);
        const fetchedData = await readFromExcel(file);
        if (fetchedData.length > 0) {
          const allKeys = Array.from(new Set(fetchedData.flatMap((obj) => Object.keys(obj))));
          const updatedData = fetchedData.map((obj) => {
            const newObj = {};
            allKeys.forEach((key) => {
              newObj[key] = obj[key] ?? '';
            });
            return newObj;
          });
          setQuestionData([...updatedData]);
        } else {
          // setError([true, 'File cannot be empty']);
          return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });
          console.log(data)

          const headers = ['question', 'option 1', 'option 2', 'option 3', 'option 4', 'image', 'answer', 'marks', 'action'];
          data[0] = headers;

          for (let i = 1; i < data.length; i++) {
            data[i].splice(5, 0, "")
            data[i].push('')
          }
          setExcelData(data);
        };

        reader.readAsBinaryString(file);

      }
      else if (fileType === "application/pdf") {
        console.log('pdf file')

      }
    } else {
      const formattedSingleQuestions = [
        singleMcqQuestion.question,
        { text: singleMcqQuestion.option1, image: singleMcqQuestion.optionImage1 },
        { text: singleMcqQuestion.option2, image: singleMcqQuestion.optionImage2 },
        { text: singleMcqQuestion.option3, image: singleMcqQuestion.optionImage3 },
        { text: singleMcqQuestion.option4, image: singleMcqQuestion.optionImage4 },
        singleMcqQuestion.questionImage,
        singleMcqQuestion.answer,
        singleMcqQuestion.marks,
        ''
      ];
      setQuestionData([...questionData, singleMcqQuestion]);

      if (excelData.length === 0) {
        setExcelData([['question', 'option 1', 'option 2', 'option 3', 'option 4', 'image', 'answer', 'marks', 'action'], formattedSingleQuestions]);
        // setImageUrl('')
        return;
      }
      if (singleMcqQuestion.image && excelData[0].length !== 8) {
        excelData[0] = ['question', 'option 1', 'option 2', 'option 3', 'option 4', 'image', 'answer', 'marks', 'action']
        for (let i = 1; i < excelData.length; i++) {
          excelData[i].splice(6, 0, '');
        }
      }

      setExcelData([...excelData, formattedSingleQuestions]);

      // setImageUrl('')
      return;
    }
    return
  }

  const handleUpdateExistingQuestion = (row) => {
    setUpdate(true);
    setUpdateQuestionId(row.id);
    setOpenAddModal(true);
    if (test.assessmentType === "MCQ-based") {
      setSingleMcqQuestion({
        option1: row.option1,
        option2: row.option2,
        option3: row.option3,
        option4: row.option4,
        optionImage1: row.optionImage1,
        optionImage2: row.optionImage2,
        optionImage3: row.optionImage3,
        optionImage4: row.optionImage4,
        questionImage: row.questionImage,
        marks: row.marks,
        answer: row.answer,
        question: row.question,
      })

    } else {
      setSingleTextQuestion({
        type: row.type,
        image: row.image,
        marks: row.marks,
        question: row.question,
        testId: test.id,
      });


    }

  };

  const handlePreviewImage = (image) => {
    setPreviewImage(image);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewImage(null);
  };

  const handleCloseAlert = async () => {
    setCustomAlert([false])
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const MCQcolumns = useMemo(() => [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div className="flex justify-center w-full">
          <Button
            color="error"
            onClick={() => {
              setDeleteQuestionId(params.row.id);
              setOpenDeleteModal(true);
            }}
          >
            <DeleteOutlineIcon style={{ fontSize: "larger" }} />
          </Button>
          <Button
            color="success"
            onClick={() => handleUpdateExistingQuestion(params.row)}
          >
            <EditIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "option1",
      headerName: "Option 1",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.optionImage1 ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => handlePreviewImage(params.row.optionImage1)}
              >
                View Image
              </Button>
            </div>
          ) : (
            <p className="text-slate-600 text-xs">{params.row.option1}</p>
          )}
        </>
      ),
    },
    {
      field: "option2",
      headerName: "Option 2",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.optionImage2 ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => handlePreviewImage(params.row.optionImage2)}
              >
                View Image
              </Button>
            </div>
          ) : (
            <p className="text-slate-600 text-xs">{params.row.option2}</p>
          )}
        </>
      ),
    },
    {
      field: "option3",
      headerName: "Option 3",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.optionImage3 ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => handlePreviewImage(params.row.optionImage3)}
              >
                View Image
              </Button>
            </div>
          ) : (
            <p className="text-slate-600 text-xs">{params.row.option3}</p>
          )}
        </>
      ),
    },
    {
      field: "option4",
      headerName: "Option 4",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.optionImage4 ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => handlePreviewImage(params.row.optionImage4)}
              >
                View Image
              </Button>
            </div>
          ) : (
            <p className="text-slate-600 text-xs">{params.row.option4}</p>
          )}
        </>
      ),
    },
    {
      field: "questionImage",
      headerName: "Question Image",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.questionImage ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => handlePreviewImage(params.row.questionImage)}
              >
                View Image
              </Button>
            </div>
          ) : (
            <p className="text-slate-600 text-xs">No Image</p>
          )}
        </>
      ),
    },
    {
      field: "answer",
      headerName: "Answer",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "marks",
      headerName: "Marks",
      flex: 1,
      align: "center",
      headerAlign: "center",
    }
  ], [questions])

  const Textcolumns = useMemo(() => [
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div className="flex justify-center w-full">
          <Button
            color="error"
            onClick={() => {
              setDeleteQuestionId(params.row.id);
              setOpenDeleteModal(true);
            }}
          >
            <DeleteOutlineIcon style={{ fontSize: "larger" }} />
          </Button>
          <Button
            color="success"
            onClick={() => handleUpdateExistingQuestion(params.row)}
          >
            <EditIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "question",
      headerName: "Question",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "marks",
      headerName: "Marks",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.image ? (
            <div>
              <Button
                variant="outlined"
                onClick={() => handlePreviewImage(params.row.image)}
              >
                View Image
              </Button>
            </div>
          ) : (
            "No Image"
          )}
        </>
      ),
    },
  ], [questions]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Dialog
        open={openEditQuestionsModal}
        onClose={() => setOpenEditQuestionsModal(false)}
        fullScreen
      >
        <Snackbar open={customAlert[0]} autoHideDuration={6000} onClose={handleCloseAlert} >
          <Alert
            onClose={handleCloseAlert}
            severity={customAlert[1]}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {customAlert[2]}
          </Alert>
        </Snackbar>
        <DialogContent>
          <h2 className="text-center text-3xl font-semibold">Edit Questions</h2>
          <div className="my-4">
            <Button onClick={handleOpenAddModal} variant="outlined">
              <AddIcon />
              Add
            </Button>
          </div>
          <div className="h-[90%]">
            <QuestionsTable
              rows={questions}
              questionLoading={questionLoading}
              columns={test.assessmentType === 'MCQ-based' ? MCQcolumns : Textcolumns}
            />
          </div>
          {
            test.assessmentType === "MCQ-based" ? <AddMCQModal setMainCustomAlert={setCustomAlert} openAddModal={openAddModal} updateQuestionId={updateQuestionId} testId={test.id} questionData={questionData} setQuestionData={setQuestionData} getQuestions={getQuestions} handleMcqQuestionDataChange={handleMcqQuestionDataChange} update={update} setUpdate={setUpdate} excelData={excelData} setExcelData={setExcelData} setOpenAddModal={setOpenAddModal} singleMcqQuestion={singleMcqQuestion} setSingleMcqQuestion={setSingleMcqQuestion} /> :
              <AddTextQuestionModal
                setMainCustomAlert={setCustomAlert}
                setUpdate={setUpdate}
                openAddModal={openAddModal}
                testId={test.id}
                getQuestions={getQuestions}
                handleTextQuestionDataChange={handleTextQuestionDataChange}
                questionData={questionData}
                excelData={excelData}
                questionType={test.assessmentType}
                setExcelData={setExcelData}
                setQuestionData={setQuestionData}
                setOpenAddModal={setOpenAddModal}
                singleTextQuestion={singleTextQuestion}
                update={update}
                setSingleTextQuestion={setSingleTextQuestion}
                updateQuestionId={updateQuestionId}
              />
          }


        </DialogContent>
        <DialogActions>
          <div className="w-full flex justify-center">
            <Button
              variant="contained"
              onClick={() => setOpenEditQuestionsModal(false)}
              color="primary"
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <div>
        <Button
          onClick={() => setOpenEditQuestionsModal(true)}
          variant="outlined"
        >
          Edit Questions
        </Button>
      </div>

      {/* IMAGE PREVIEW MODAL */}
      <ImagePreviewModal
        open={openPreview}
        handleClose={handleClosePreview}
        style={style}
        previewImage={previewImage}
      />

      {/* DELETE MODAL */}
      <DeleteModal
        setMainCustomAlert={setCustomAlert}
        testId={test.id}
        getQuestions={getQuestions}
        questionId={deleteQuestionId}
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />

    </div>
  );
};

export default React.memo(EditQuestionsButton);
