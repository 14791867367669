// actions/messageActions.js

// Action creator for adding a new message
const addAdminMessages = (message) => ({
  type: "ADD_ADMIN_MESSAGES",
  payload: message,
});
// const addUserMessages = (message) => ({
//   type: "ADD_USER_MESSAGE",
//   payload: message,
// });
const connectedUsers = (message) => ({
  type: "CONNECTED_USER",
  payload: message,
});
const setNewMessages = (message) => ({
  type: "NEW_MESSAGES",
  payload: message,
});
const removeNewMessages = (message) => ({
  type: "REMOVE_NEW_MESSAGES",
  payload: message,
});
const setCompanyId = (id) => ({
  type: "COMPANY_ID",
  payload: id,
});
const setShowAlert = (boolean) => ({
  type: "SHOW_ALERT",
  payload: boolean,
});

const clearMessages = () => ({
  type: "CLEAR_MESSAGES",
});

const socketState = (socket) => ({
  type: "SOCKET_STATE",
  payload: socket,
});

export {
  addAdminMessages,
  setCompanyId,
  socketState,
  setNewMessages,
  removeNewMessages,
  setShowAlert,
  connectedUsers,
  clearMessages,
};
