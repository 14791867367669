import {   Paper, Typography } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonDigging } from "@fortawesome/free-solid-svg-icons";

function UnderDevelopement({customLeft}) {
  return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          top: "30%",
          bgcolor:'#F0F0F0',
          left: customLeft ? customLeft : "20%",
          p: 10,
        }}
      >
        <FontAwesomeIcon size="3x" icon={faPersonDigging} />
        <Typography variant="h4" sx={{ mt: 3 }}>
          This page is Under UnderDevelopement{" "}
        </Typography>
        <Typography variant="h6" sx={{mt:2}}>Thank you for waiting</Typography>
      </Paper>
  );
}

export default UnderDevelopement;
