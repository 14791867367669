import React, { useEffect, useState, useMemo, useRef } from "react";
import { io } from "socket.io-client";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ChatIcon from "@mui/icons-material/Chat";
import jwtDecode from "jwt-decode";
import { Alert, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { reloadPage } from "../../store/actions/test";
import { SOCKET_URL } from "../../constant";

const ChatSupport = () => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const socket = useMemo(() => io(SOCKET_URL), []);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const userName = localStorage.getItem("name");
  const companyId = localStorage.getItem("cid");
  const [showModal, setShowModal] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const [timeAlert, setTimeAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [recipientOnline, setRecipientOnline] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleMessageReceive = (data) => {
      if (data.message === "--RELOAD--") {
        setTimeAlert(true);
        dispatch(reloadPage(true));
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return;
      }
      setNewMessage(true);
      const audio = new Audio("mixkit-interface-option-select-2573.wav");
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
      setMessages((prevMessages) => [...prevMessages, data]);
      scrollToBottom();
    };

    socket.on("connect", () => {
      socket.emit("user-connected", {
        socketId: socket.id,
        email: jwtDecode(token).email,
        companyId,
        userName,
        userId,
        sender: "user",
      });
    });

    socket.on("receive-message", handleMessageReceive);

    socket.on("connectedUsers", (users) => {
      // setOnlineUsers(users);
      setRecipientOnline(users[companyId]?.online || false);
    });

    return () => {
      socket.off("receive-message", handleMessageReceive);
      socket.off("connect");
      socket.off("connectedUsers");
    };
  }, [socket, userId, token, companyId]);

  const sendMessage = async () => {
    try {
      socket.emit("send-message", {
        message,
        userId,
        recipientId: companyId,
        sender: "user",
      });
      setMessage("");
      setMessages((prevMessages) => [
        ...prevMessages,
        { message, userId, companyId, sender: "user" },
      ]);
      scrollToBottom();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showModal) {
      scrollToBottom();
    }
  }, [showModal]);

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb {
        background: #004e8f;
        border-radius: 10px;
      }
      .custom-scrollbar::-webkit-scrollbar-thumb:hover {
        background: #a0c7dc;
      }
    `;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <>
      {timeAlert && (
        <Alert
          className="fixed top-16 z-[100] left-[50%] transform -translate-x-[50%]"
          severity="success"
        >
          Your time has been extended. Page will be reload in 5 seconds.
        </Alert>
      )}
      <div className="fixed bottom-20 right-20">
        {showModal && (
          <div className="flex-1 w-[400px] h-96 bg-slate-50 rounded-2xl ml-auto flex flex-col">
            <header className="border-b p-4">
              <h1 className="text-2xl text-[#004e8f] font-semibold flex items-center">
                <SupportAgentRoundedIcon fontSize="large" />
                <span className="ml-2">Support</span>
                {
                  <span
                    className={`ml-2 ${
                      recipientOnline ? "bg-green-500" : "bg-red-500"
                    } h-4 w-4 rounded-full text-sm`}
                  ></span>
                }
              </h1>
            </header>

            <div className="flex-1 overflow-y-auto px-4 py-2 custom-scrollbar">
              {messages.map((item, index) => (
                <div
                  key={index}
                  className={`flex ${
                    item.sender === "user" ? "justify-end" : ""
                  } my-2`}
                >
                  <div
                    style={{
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      hyphens: "auto",
                    }}
                    className={`${
                      item.sender === "user"
                        ? "bg-[#004e8f] text-white"
                        : "bg-[#b0c8dc]"
                    } max-w-[55%] rounded-xl p-1`}
                  >
                    <p className="px-2 text-sm">{item.message}</p>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <footer className="border-gray-300 p-4 w-full">
              <Paper
                elevation={8}
                className="flex border p-2 rounded-lg shadow-xl items-center"
              >
                <input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  type="text"
                  placeholder="Type a message..."
                  className="w-full text-xs p-2 rounded-md border border-gray-400 focus:outline-none focus:border-none"
                />
                <button
                  onClick={sendMessage}
                  className="bg-[#004e8f] text-white px-3 py-1 rounded-md ml-2"
                >
                  Send
                </button>
              </Paper>
            </footer>
          </div>
        )}
        <div
          onClick={() => {
            setNewMessage(false);
            setShowModal(!showModal);
          }}
          className={`ml-auto mt-4 select-none ${
            newMessage ? "animate-bounce" : ""
          } cursor-pointer h-16 w-16 flex justify-center items-center rounded-full bg-[#004e8f]`}
        >
          {newMessage && (
            <div className="h-8 w-8 shadow-2xl absolute -top-2 -right-1 bg-gray-200 rounded-full grid place-items-center font-medium text-xl">
              1
            </div>
          )}
          <ChatIcon className="text-[#b0c8dc]" fontSize="large" />
        </div>
      </div>
    </>
  );
};

export default ChatSupport;
