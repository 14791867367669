const stateInit = { option: "home" };

function navReducer(state = stateInit, action) {
  switch (action.type) {
    case "select":
      return {
        ...state,
        option: action.payload,
      };
    default:
      return state;
  }
}

export default navReducer;
