import { combineReducers } from "redux";
import user from "./user";
import nav from "./nav";
import auth from "./auth";
import toolArrayReducer from "./toolsArray";
import tests from "./tests";
import selectedTest from "./selectedTest";
import proctorReducer from "./proctor";
import proctorcountReducer from './proctorcount'; 
import messageReducer from "./newMessage";

const rootReducer = combineReducers({
  user,
  nav,
  auth,
  messageReducer,
  toolArrayReducer,
  tests,
  selectedTest,
  proctorReducer,
  proctorcountReducer,
});

export default rootReducer;

