// import React from "react";
import { Navigate } from "react-router-dom";
import UserSideBar from "./UserSideBar";
import Sidebar from "./SideBar";

function UserRoute({ isAuthenticated, children, user }) {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (user.role === 2) {
    return <Navigate to="/dashboard" />;
  }

  return <Sidebar>{children}</Sidebar>;
}

export default UserRoute;