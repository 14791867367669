import React, { useState, useEffect, useCallback, useMemo } from "react";
import { createTheme, Paper, ThemeProvider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Navigation from "./components/Navigation/Navigation";
import "./App.css";
import jwtDecode from "jwt-decode";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdminMessages,
  connectedUsers,
  setNewMessages,
  setShowAlert,
  socketState,
} from "./store/actions/newMessage";
import { SOCKET_URL } from "./constant";

// Define the MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#004E8F",
      lighter: "#B0C8DC",
    },
    secondary: {
      gold: "#FFB61D",
      main: "#FFFFFF",
    },
    light: {
      main: "#F5F5F5",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

function App() {
  const dispatch = useDispatch();
  const companyId =
    useSelector((state) => state.messageReducer?.companyId) ||
    localStorage.getItem("cid");
  const [userRole, setUserRole] = useState(null);
  const [aalert, setAalert] = useState(false);
  const socket = useMemo(
    () => io(SOCKET_URL),
    [companyId]
  );
  dispatch(socketState(socket));

  // Memoize the message receive handler
  const handleMessageReceive = useCallback(
    (data) => {
      const loc = window.location.hash;
      const audio = new Audio("mixkit-interface-option-select-2573.wav");
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });

      if (loc !== "#/support") {
        dispatch(setShowAlert(true));
        setAalert(true);
      }
      dispatch(addAdminMessages(data));
      dispatch(setNewMessages(data.userId));
    },
    [dispatch]
  );

  useEffect(() => {
    const token = localStorage.getItem("token") || null;

    if (token) {
      const decodedToken = jwtDecode(token);

      if (decodedToken.role === 2 && companyId) {
        setUserRole(2);

        socket.on("connect", () => {
          socket.emit("user-connected", {
            socketId: socket.id,
            userId: companyId,
            sender: "admin",
          });
        });

        socket.on("receive-message", handleMessageReceive);

        socket.on("connectedUsers", (data) => {
          const myUsers = Object.values(data).filter(
            (e) => e.companyId === companyId
          );
          dispatch(connectedUsers(myUsers));
        });
      } else {
        setUserRole(null);
      }
    }

    // Cleanup function
    return () => {
      if (token) {
        socket.off("receive-message", handleMessageReceive);
        socket.off("connect");
        socket.off("connectedUsers");
      } else {
        socket.disconnect();
      }
    };
  }, [companyId, handleMessageReceive, socket]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAalert(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [aalert]);

  const handleHideAlert = () => {
    setAalert(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {aalert && userRole === 2 && (
        <Paper
          sx={{ bgcolor: "#004E8F", color: "white" }}
          elevation={8}
          className="absolute text-center z-[101] px-2 py-2 left-[50%] transform mt-10 -translate-x-[50%] w-60"
        >
          New Message!
          <CloseRoundedIcon
            className="cursor-pointer ml-auto"
            onClick={handleHideAlert}
            sx={{ color: "white", marginLeft: 1 }}
          >
            Cancel
          </CloseRoundedIcon>
        </Paper>
      )}
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
