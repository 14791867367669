import React from "react";
import Navbar from "./Navbar";  
import { Navigate } from "react-router-dom";

function PublicRoute({  children,user }) {
  const token = localStorage.getItem('token')  
  if(token   ){
    return <Navigate to='/dashboard' replace/>
  }

  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default PublicRoute;
