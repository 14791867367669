import {
  faAt,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Divider,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import auth from "../../store/actions/auth";
import authService from "../../store/services/auth.service";
import { Link } from "react-router-dom";

function Signup(props) {
  const [inputs, setInputs] = useState({
    name: "",
    companyname: "",
    availableMinutes:"",
    email: "",
    password: "",
    re_password: "",
  });

  const [error, setError] = useState("");
  const [vissible, setVissible] = useState(false);
  const [vissibleRePass, setVissibleRePass] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleOnChange(e) {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function handleSignup() {
    if (
      inputs.email === "" ||
      inputs.password === "" ||
      inputs.availableMinutes === '' ||
      inputs.re_password === "" ||
      inputs.name === ""
    ) {
      return setError("Please Enter all Fields");
    }
    if (inputs.password !== inputs.re_password) {
      return setError("Passwords do not match");
    }
    if (!inputs.email.includes("@")) {
      return setError("Please enter a valid email address");
    }

    try {
      setLoading(true);
      await authService.adminsignup(
        inputs.name,
        inputs.email,
        inputs.password,
        inputs.companyname,
        inputs.availableMinutes
      );
      setLoading(false);
      setError("");
      alert("Signup Process Completed");

      props.login();
      // window.location.reload();
    } catch (error) {
      setLoading(false);
      setError(error.data.error);
    }
  }

  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: "900px",
        margin: "auto",
        padding: "20px",
        mt: 5,
      }}
    >
      <Typography fontWeight={800} sx={{ mb: 1 }}>
        Admin Name
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="name"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Full Name"
          inputProps={{ "aria-label": "search google maps" }}
          value={inputs.name}
          onChange={handleOnChange}
        />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2 }}>
        Company Name
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="companyname"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Company Name"
          inputProps={{ "aria-label": "search google maps" }}
          value={inputs.companyname}
          onChange={handleOnChange}
        />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2 }}>
      Available Minutes
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
        type="number"
          id="availableMinutes"
          sx={{ ml: 1, flex: 1 }}
          placeholder=""
          inputProps={{ "aria-label": "search google maps" }}
          value={inputs.availableMinutes}
          onChange={handleOnChange}
        />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2, userSelect: "none" }}>
        Email
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="email"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Email"
          value={inputs.email}
          onChange={handleOnChange}
        />
        <FontAwesomeIcon color="#004E8F" icon={faAt} />
      </Paper>  
      <Typography fontWeight={800} sx={{ mb: 1, mt: 2, userSelect: "none" }}>
        Password
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="password"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter Your Password"
          type={vissible ? "text" : "password"}
          value={inputs.password}
          onChange={handleOnChange}
        />
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setVissible((prevState) => !prevState);
          }}
          color="#004E8F"
          icon={vissible ? faEye : faEyeSlash}
        />
      </Paper>

      <Typography fontWeight={800} sx={{ mb: 1, mt: 2, userSelect: "none" }}>
        Re-Enter Password
      </Typography>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <InputBase
          id="re_password"
          sx={{ ml: 1, flex: 1 }}
          placeholder="Re-Enter Your Password"
          type={vissibleRePass ? "text" : "password"}
          value={inputs.re_password}
          onChange={handleOnChange}
        />
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          onClick={() => {
            setVissibleRePass((prevState) => !prevState);
          }}
          color="#004E8F"
          icon={vissibleRePass ? faEye : faEyeSlash}
        />
      </Paper>

      {error !== "" && (
        <Typography variant="body2" color="red" ml={1}>
          {error}
        </Typography>
      )}

      <Button
        variant="contained"
        sx={{ width: "100%", mt: 8, ml: 0.5, mb: 2 }}
        color="primary"
        onClick={handleSignup}
        disabled={loading}
      >
        {loading ? "please wait" : "Register Admin"}
        {loading && (
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            style={{ marginLeft: "8px" }}
          />
        )}
      </Button>
     
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(auth.login(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
