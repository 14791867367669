import {
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  CircularProgress,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import * as XLSX from "xlsx";

const GenerateQuestionModal = ({
  openGenerateQuestionModal,
  setOpenGenerateQuestionModal,
}) => {
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [generatedQuestionValues, setGeneratedQuestionValues] = useState({
    nQuestions: "",
    difficultyLevel: "",
    jobRoles: "",
  });
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [enablePdf, setEnablePdf] = useState(false)
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");

  const handleOnchangeGenerateQuestions = (e) => {
    const { name, value } = e.target;
    setGeneratedQuestionValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFileName(file.name);
      setUploadedFile(file);
    }
  };

  const handleTagInputChange = (e) => {
    setCurrentTag(e.target.value);
  };

  const addTag = () => {
    if (currentTag && !tags.includes(currentTag)) {
      setTags([...tags, currentTag]);
      setCurrentTag("");
    }
  };

  const handleTagKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addTag();
    }
  };

  const handleTagRemove = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const { nQuestions, jobRoles, difficultyLevel } = generatedQuestionValues;

    if (!jobRoles || !difficultyLevel) {
      alert("Please fill in all fields");
      return;
    }
    if (uploadedFile && tags.length < 1) {
      alert("Please add tags.");
      return;
    }
    if (enablePdf && (!uploadedFile || tags.length == 0)) {
      if (!uploadedFile) {
        alert('Please upload PDF file.')
      } else {
        alert("Please add tags.");
      }
      return;
    }
    if(!nQuestions){
      alert("Enter Question Numbers.")
      return
    }

    const formData = new FormData();
    formData.append("n_questions", nQuestions);
    formData.append("job_roles", jobRoles);
    formData.append("difficulty_level", difficultyLevel);
    formData.append("file", uploadedFile);
    formData.append("tags", tags.join(',')); // Add tags to formData
    console.log(generatedQuestionValues)

    setLoading(true);

    try {
      const res = await iparhaiServer.post(
        "/api/question/generate-AI-roleQuestions",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setGeneratedQuestions(res.data.data.slice(0, nQuestions));
    } catch (error) {
      console.log(error)
      if (error.message === 'Network Error') {
        alert("Please check your internet connection and try again.")
      }
      if (error.response.status === 500) {
        alert('An error occurred. Please try again.')
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEnablePdf = (e) => {
    setUploadedFile(null)
    setUploadedFileName('')
    setGeneratedQuestionValues({
      difficultyLevel: "",
      jobRoles: "",
    })
    setTags([])
    setGeneratedQuestions([])
    setEnablePdf(e.target.checked)
  }

  const stripOptionPrefix = (option) => {
    return /^[A-D]\)\s/.test(option) ? option.substring(3) : option;
  };

  const downloadExcel = () => {
    if (generatedQuestions.length === 0) {
      alert("No questions to download");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(
      generatedQuestions.map((q) => ({
        question: q.question,
        option1: stripOptionPrefix(q.options[0]),
        option2: stripOptionPrefix(q.options[1]),
        option3: stripOptionPrefix(q.options[2]),
        option4: stripOptionPrefix(q.options[3]),
        answer: stripOptionPrefix(q.options[q.answer.charCodeAt(0) - 65] || q.answer),
        marks: 1,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Generated Questions");
    XLSX.writeFile(
      workbook,
      `generated_questions_${generatedQuestionValues.jobRoles}.xlsx`
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGenerateQuestionModal}
        onClose={() => {
          setOpenGenerateQuestionModal(false);
          setGeneratedQuestions([]);
          setGeneratedQuestionValues({
            difficultyLevel: "",
            jobRoles: "",
          });
          setTags([]);
          setCurrentTag("");
        }}
        closeAfterTransition
      >
        <Fade in={openGenerateQuestionModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 800,
              maxHeight: '90vh',
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflow: "auto",
            }}
          >
            <div className="bg-[#004E8F] px-3 text-white rounded-2xl py-1">
              <h3 className="text-center text-xl font-semibold">CAUTION</h3>
              <p className="text-balance text-center text-sm">Please review the generated questions carefully before using them. While the system aims to provide accurate results, there is a possibility of occasional mistakes. It's always a good idea to double-check the content to ensure it meets your expectations.</p>
            </div>
            <h2
              className="text-2xl my-2 font-medium"
              id="transition-modal-title"
            >
              AI Generated Questions
            </h2>
            <TextField
              fullWidth
              label="Number of Questions ( max 20)"
              type="number"
              inputProps={{ min: "1", max: "20" }}
              name="nQuestions"
              value={generatedQuestionValues.nQuestions}
              onChange={(e) => {
                const enteredValue = e.target.value;
                if ((/^\d+$/.test(enteredValue) || enteredValue === "") && (enteredValue === "" || parseInt(enteredValue) <= 20)) {
                  setGeneratedQuestionValues({ ...generatedQuestionValues, nQuestions: enteredValue });
                }
              }}
              sx={{ mb: 2 }}
            />

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Difficulty Level</InputLabel>
              <Select
                name="difficultyLevel"
                value={generatedQuestionValues.difficultyLevel}
                onChange={handleOnchangeGenerateQuestions}
                label="Difficulty Level"
              >
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Topic / Job Roles"
              name="jobRoles"
              value={generatedQuestionValues.jobRoles}
              onChange={handleOnchangeGenerateQuestions}
              sx={{ mb: 2 }}
            />
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "16px",
                  marginTop: "16px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enablePdf}
                      onChange={handleEnablePdf}
                    />
                  }
                  label="Do you want to generate questions from PDF file?"
                />
              </div>
              {enablePdf && (
                <div className="flex justify-center items-center gap-4">
                  <TextField
                    // fullWidth
                    className="flex-grow"
                    label="Add Tags"
                    value={currentTag}
                    onChange={handleTagInputChange}
                    onKeyDown={handleTagKeyDown}
                    sx={{ mb: 2 }}
                  />
                  <Button variant="contained" color="primary" onClick={addTag} sx={{ mb: 2 }}>
                    Add Tag
                  </Button>
                </div>
              )}
            </>

            <div className="mb-4">
              {tags.map((tag, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handleTagRemove(index)}
                  sx={{ m: 0.5 }}
                >
                  {tag} x
                </Button>
              ))}
            </div>
            <div className="flex gap-4 flex-wrap">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {loading ? "Generating..." : "Generate"}
              </Button>
              {enablePdf && <>
                <input
                  accept=".pdf"
                  style={{ display: "none" }}
                  id="pdfFileInput"
                  type="file"
                  onClick={(event) => (event.target.value = null)} // Reset file input
                  onChange={handleFileChange}
                />
                <label htmlFor="pdfFileInput">
                  <Button
                    variant="contained"
                    component="span"
                    color={uploadedFileName ? "error" : "primary"}
                  >
                    {uploadedFileName
                      ? `Change PDF File (${uploadedFileName})`
                      : "Upload PDF File"}
                  </Button>
                </label>
              </>}
              {generatedQuestions.length > 0 && (
                <Button variant="outlined" onClick={downloadExcel}>
                  Download as Excel
                </Button>
              )}
            </div>

            {generatedQuestions.length > 1 && (
              <div className="my-4">
                <Divider><span className={`${generatedQuestions.length == generatedQuestionValues.nQuestions ? 'bg-[#004E8F]' : 'bg-red-600'} px-3 text-sm rounded-2xl text-white`}>{` ${generatedQuestions.length} QUESTIONS`}</span></Divider>
              </div>
            )}

            {generatedQuestions.length > 0 && (
              <div className="h-96 overflow-auto">
                <table style={{ borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Question
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Option 1
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Option 2
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Option 3
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Option 4
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Answer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {generatedQuestions.map((item, index) => (
                      <tr className="text-xs" key={index}>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {item.question}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {stripOptionPrefix(item.options[0])}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {stripOptionPrefix(item.options[1])}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {stripOptionPrefix(item.options[2])}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {stripOptionPrefix(item.options[3])}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {stripOptionPrefix(
                            item.options[item.answer.charCodeAt(0) - 65]
                          ) || item.answer}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default GenerateQuestionModal;
