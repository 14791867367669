import { Button } from '@mui/material'
import React from 'react'

const McqQuestionPreview = ({ excelData, handleOpen, handleDelete }) => {
    return (
        <div>
            <table
                style={{
                    fontFamily: "Arial, Helvetica, sans-serif",
                    borderCollapse: "collapse",
                    width: "100%",
                    marginTop: "10px",
                    overflowX: "scroll",
                }}
            >
                <thead>
                    {excelData.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            style={{
                                backgroundColor: rowIndex === 0 ? "#004E8F" : "",
                                color: rowIndex === 0 ? "white" : "",
                            }}
                        >
                            {row.map((cell, cellIndex) =>
                                rowIndex === 0 ? (
                                    <th
                                        key={cellIndex}
                                        style={{
                                            border: "1px solid #ddd",
                                            padding: "8px",
                                            paddingTop: "12px",
                                            paddingBottom: "12px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {cell}
                                    </th>
                                ) : null
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {excelData.filter(row => !row.every(cell => !cell || (typeof cell === 'object' && Object.keys(cell).length === 0))).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) =>
                                rowIndex !== 0 ? (
                                    <td
                                        key={cellIndex}
                                        style={{ border: "1px solid #ddd", padding: "8px" }}
                                    >
                                        {cellIndex === 1 ||
                                            cellIndex === 4 ||
                                            cellIndex === 3 ||
                                            (cellIndex === 2 && cell) ? (
                                            <div>
                                                <p>{cell.text || cell}</p>
                                                {cell.image && (
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => handleOpen(cell.image)}
                                                    >
                                                        Preview
                                                    </Button>
                                                )}
                                            </div>
                                        ) : cellIndex === 5 && cell ? (
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleOpen(cell)}
                                            >
                                                Preview
                                            </Button>
                                        ) : cellIndex === 8 ? (
                                            <Button
                                                color="error"
                                                variant="outlined"
                                                onClick={() => handleDelete(rowIndex)}
                                            >
                                                Delete
                                            </Button>
                                        ) : (
                                            cell
                                        )}
                                    </td>
                                ) : null
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    )
}

export default McqQuestionPreview
