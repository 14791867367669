import {
  faSquareCheck,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

function InfoCard(props) {
  const { title, desc, icon } = props;
  return (
    <Box
      display="flex"
      gap={3}
      width="100%"
      borderRadius={3}
      p={2}
      sx={{
        justifyContent: "center",
        bgcolor: "white",
        color: "primary.main",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <FontAwesomeIcon
        fontSize={"1.85rem"}
        icon={icon}
        style={{ paddingTop: "5px", width: "5%" }}
      />
      <Box width="70%">
        <Typography color="black" fontWeight={600}>
          {title}
        </Typography>
        <Typography color="black" fontSize={"0.75rem"}>
          {desc}
        </Typography>
      </Box>
      <Box sx={{color:'primary.main',width: "12.5%",textAlign:'center'}}>
        <FontAwesomeIcon
          icon={faSquareCheck}
          // style={{ paddingTop: "5px", width: "12.5%"}}
        />
      </Box>
      <FontAwesomeIcon
        icon={faSquareXmark}
        color="#C4C4C4"
        style={{ paddingTop: "5px", width: "12.5%", display: "flex" }}
      />
    </Box>
  );
}

export default InfoCard;
