import * as XLSX from "xlsx";

const readFromExcel = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 2 });

        resolve(parsedData);
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsBinaryString(file);
  });
};

export { readFromExcel };