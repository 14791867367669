const stateInit = { switches: 0 };

function proctorReducer(state = stateInit, action) {
  switch (action.type) {
    case "switchCount":
      return { ...state, switches: action.payload };
    default:
      return state;
  }
}

export default proctorReducer;