import React, { useEffect, useState } from "react";
import { Box, Grid, Pagination, Paper, Typography } from "@mui/material";
import ScheduleCard from "../../components/ScheduleTest/ScheduleCard";
import testService from "../../store/services/test.service";
import SideBar from "../../components/Navigation/SideBar";
import jwtDecode from "jwt-decode";
import NetworkStatus from "../NetworkStatus";

function Scheduled() {
  const [page, setPage] = useState(1);
  const [testScores, setTestScores] = useState([]);
  const itemsPerPage = 1; // Define number of items per page

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const userId = jwtDecode(token).id;
      testService
        .getAllTests(userId)
        .then((res) => {
          Promise.all(
            res.data.tests.map((test) => testService.getAllScores(test.id))
          )
            .then((scoreResponses) => {
              const updatedScores = res.data.tests.map((test, index) => ({
                test: test,
                scores: scoreResponses[index].data,
              }));
              setTestScores(updatedScores);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <>
      <Box
        height="100%"
        sx={{
          position: "relative",
          bgcolor: "#F8F8F8",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={0} sm={3}>
            <SideBar />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                ml: {
                  xs: "2rem",
                  sm: "6.5rem",
                  md: "1rem",
                },
                mr: "1rem",
              }}
            >
              <Typography mt={1} fontWeight={800}>
                Your Assessments
              </Typography>
              <Typography mb={2} fontSize={14}>
                Manage your Scheduled Assessments
              </Typography>
              {testScores && testScores.length > itemsPerPage && (
                <Pagination
                  count={Math.ceil(testScores.length / itemsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  sx={{ display: "flex", justifyContent: "center" }}
                />
              )}
              <Paper sx={{ p: 1, m: 2, bgcolor: "primary.lighter" }}>
                {testScores &&
                  testScores.slice(startIndex, endIndex).map((test, index) => (
                    <ScheduleCard key={index} test={test} />
                  ))}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Scheduled;
