import CryptoJS from 'crypto-js';

function fetchAndDecryptCode(encryptedCode,key) {
 try {
    const [ivHex, encryptedText] = encryptedCode.split(':');
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encryptedWordArray = CryptoJS.enc.Hex.parse(encryptedText);
    const keyWordArray = CryptoJS.enc.Hex.parse(key);

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedWordArray },
      keyWordArray,
      { iv }
    );

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedString
 } catch (error) {
    console.log(error)
    throw new Error(error)
 }
  }

  export {fetchAndDecryptCode}